import React from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { PX_TO_REM } from '../../helpers/Constants'
import { Translation } from '../../helpers/Translation'
import { redirectToMyDoopic } from '../../helpers/Utils'

import Tooltip from '../../components/Tooltip'

import OrderId from './OrderId'
import ImageTypes from './ImageTypes'
import Stats from './Stats'
import Avatars from './Avatars'
import Controls from './Controls'
import Chat from './Chat'
import Filters from './Filters'
import ImageSizeRange from './ImageSizeRange'
import OrderStatusUpdate from './OrderStatusUpdate'
import Redo from './Redo'
import SaveSettings from './SaveSettings'
import ToggleCombine from './ToggleCombine'
import ProfileMenu from './ProfileMenu'

import { ReactComponent as InfoIconSvg } from '../../svg/info_icon.svg'
import { ReactComponent as HomeSvg } from '../../svg/home.svg'

import './index.scss'

const Header = () => {
  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const layoutState = useStoreState((state) => ({
    isInfosOpened: state.layout.isInfosOpened,
    isListView: state.layout.isListView,
  }))

  const layoutActions = useStoreActions((actions) => ({
    updateInfosOpened: actions.layout.updateInfosOpened,
    updateRedoModalOpened: actions.layout.updateRedoModalOpened,
  }))

  const orderState = useStoreState((state) => ({
    gallery: state.order.gallery,
    imageType: state.order.imageType,
  }))

  const handleInfoClick = () => {
    layoutActions.updateInfosOpened(!layoutState.isInfosOpened)
  }

  return (
    <div className="header">
      <div className="header--middle__wrap--left">
        <Tooltip
          text={Translation.home}
          position="bottom"
          offset={PX_TO_REM['60']}
        >
          <div
            onClick={redirectToMyDoopic}
            className="header--wraps header--back__wrap"
          >
            <HomeSvg />
          </div>
        </Tooltip>

        <Tooltip
          text={Translation.general_order_info}
          position="bottom"
          offset={PX_TO_REM['60']}
        >
          <div
            className={`header--wraps header--image-info__wrap ${layoutState.isInfosOpened
              ? 'header--image-info__wrap--active' : ''}`}
            onClick={handleInfoClick}
          >
            <InfoIconSvg />
          </div>
        </Tooltip>

        {!layoutState.isListView ? (
          <Redo />
        ) : (
          <div className="header--wraps header--placeholder__wrap">
            {' '}
          </div>
        )}

        {!userState.user.is_admin && (
          orderState.gallery?.is_partial_delivery
          || orderState.gallery?.is_redelivery
          || orderState.gallery?.is_approval_required
          || orderState.gallery?.is_client_qa_comment) ? (
            <OrderStatusUpdate />
          ) : (
            <div className="header--wraps header--placeholder__wrap">
              {' '}
            </div>
          )}

        <OrderId />

        <ImageTypes />

        {!layoutState.isListView ? (
          <Stats />
        ) : (
          <div className="header--wraps header--placeholder__wrap">
            {' '}
          </div>
        )}
      </div>

      <div className="header--middle__wrap--middle" />

      <div className="header--middle__wrap--right">
        {!layoutState.isListView ? (
          <ImageSizeRange />
        ) : (
          <div className="header--wraps header--placeholder__wrap">
            {' '}
          </div>
        )}

        {!layoutState.isListView ? (
          <Avatars />
        ) : (
          <div className="header--wraps header--placeholder__wrap">
            {' '}
          </div>
        )}

        <Controls />

        <div className="header--wraps header--filters__wrap">
          {!layoutState.isListView ? (
            <SaveSettings isVisible={false} />
          ) : (
            <div className="header--wraps header--placeholder__wrap">
              {' '}
            </div>
          )}

          {/* eslint-disable-next-line no-nested-ternary */}
          {!layoutState.isListView ? (
            userState.user.is_admin ? (
              <ToggleCombine />
            ) : (
              <div className="header--wraps header--placeholder__wrap">
                {' '}
              </div>
            )
          ) : (
            <div className="header--wraps header--placeholder__wrap">
              {' '}
            </div>
          )}

          {!layoutState.isListView ? (
            <Chat />
          ) : (
            <div className="header--wraps header--placeholder__wrap">
              {' '}
            </div>
          )}

          {!layoutState.isListView ? (
            <Filters />
          ) : (
            <div className="header--wraps header--placeholder__wrap" style={{ width: `${PX_TO_REM['214']}rem` }}>
              {' '}
            </div>
          )}

          <ProfileMenu />
        </div>
      </div>
    </div>
  )
}

export default Header
