import { useEffect, useRef } from 'react'
import spacetime from 'spacetime'

import {
  lang,
  Translation,
} from './Translation'
import {
  Cookie,
  isLocal,
} from './Cookie'
import {
  ADMIN_GALLERY_PREFIX,
  ADMIN_LIST_PREFIX,
  CLIENT_GALLERY_PREFIX,
  CLIENT_LIST_PREFIX,
  DEFAULT_DRAWING_TYPE,
  DEFAULT_IS_EXPANDED,
  DEFAULT_TEXT_COLOR,
  DEFAULT_TIMEZONE,
  GALLERY_BG_DEFAULT,
  GRID_COLORS,
  HORIZONTAL_GRID_CLASS,
  IMAGE_ERROR_CLASS,
  IMAGE_ERROR_CODES,
  IMAGE_PREVIEWS_WIDTHS,
  IMAGE_SIZES,
  IMAGE_SORTINGS,
  IMAGE_STATUSES,
  IMAGE_TYPES,
  INFO_ERROR_CLASS,
  PATH_COLORS,
  SHAPE_STROKE_WIDTH_RATIO,
  STROKE_WIDTH_RATIO,
  VERTICAL_GRID_CLASS,
} from './Constants'
import {
  GALLERY_PREFIX_RE,
  MY_DOOPIC_URLS,
} from './Urls'

export const isAuthenticated = () => !!Cookie.getCookie('PHPSESSID')

export const redirectToMyDoopic = () => {
  if (isAuthenticated()) {
    window.location.href = MY_DOOPIC_URLS.dashboard
  } else {
    window.location.href = MY_DOOPIC_URLS.login
  }
}

export const log = (...arg) => {
  // eslint-disable-next-line no-console
  if (isLocal) console.log(...arg)
}

export const isAltOrCommandKey = (e) => e.altKey || e.metaKey

export const debounce = (func, timeout = 100) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

export const windowWidth = () => document.documentElement.clientWidth || document.body.clientWidth

log('lang', `"${lang}"`, 'windowWidth', windowWidth())

// not used yet
export const localizeDate = (date, withHour = true) => {
  if (date === null || date === '') return '-'
  let targetTimeInUserTimezone
  try {
    const targetDateTime = spacetime(date, DEFAULT_TIMEZONE)

    const now = spacetime.now()

    let currentTimezone = now.timezone().name
    if (currentTimezone === 'cet') currentTimezone = DEFAULT_TIMEZONE
    targetTimeInUserTimezone = targetDateTime.goto(currentTimezone)
  } catch (e) {
    return date
  }

  if (withHour) {
    return `${new Date(targetTimeInUserTimezone.epoch).toLocaleDateString('en-GB').replace(/\//g, '.')} 
  ${new Date(targetTimeInUserTimezone.epoch).toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
  })}`
  }

  return `${new Date(targetTimeInUserTimezone.epoch).toLocaleDateString('en-GB').replace(/\//g, '.')}`
}

export const getGalleryUrl = (isAdmin, imageType, orderId, imageSize, imageSort, isCombineExplode) => {
  let galleryUrl

  if (isAdmin) galleryUrl = ADMIN_GALLERY_PREFIX
  else galleryUrl = CLIENT_GALLERY_PREFIX

  galleryUrl += `/${imageType}/${orderId}`

  if (imageSize !== IMAGE_SIZES.small
    || imageSort !== IMAGE_SORTINGS.id
    || isCombineExplode
  ) galleryUrl += `/${imageSize}`

  if (imageSort !== IMAGE_SORTINGS.id
    || isCombineExplode
  ) galleryUrl += `/${imageSort}`

  if (isCombineExplode) galleryUrl += '/1'

  return galleryUrl
}

export const getListUrl = (isAdmin, imageType, orderId) => {
  let galleryUrl

  if (isAdmin) galleryUrl = ADMIN_LIST_PREFIX
  else galleryUrl = CLIENT_LIST_PREFIX

  galleryUrl += `/${imageType}/${orderId}`

  return galleryUrl
}

export const getPreviewWidth = (imageSize) => {
  if (imageSize === IMAGE_SIZES.medium) return IMAGE_PREVIEWS_WIDTHS.medium
  if (imageSize === IMAGE_SIZES.large) return IMAGE_PREVIEWS_WIDTHS.large
  return IMAGE_PREVIEWS_WIDTHS.small
}

export const getEndpointUrl = (isAdmin, url) => url.replace(GALLERY_PREFIX_RE, isAdmin
  ? ADMIN_GALLERY_PREFIX : CLIENT_GALLERY_PREFIX)

const notFoundGalleryEn = '/svg/not_found_gallery_en.svg'
const notFoundGalleryDe = '/svg/not_found_gallery_de.svg'
const notFoundGalleryIt = '/svg/not_found_gallery_it.svg'

const brokenFileGalleryEn = '/svg/broken_file_gallery_en.svg'
const brokenFileGalleryDe = '/svg/broken_file_gallery_de.svg'
const brokenFileGalleryIt = '/svg/broken_file_gallery_it.svg'

const previewGeneratedGalleryEn = '/svg/preview_generated_gallery_en.svg'
const previewGeneratedGalleryDe = '/svg/preview_generated_gallery_de.svg'
const previewGeneratedGalleryIt = '/svg/preview_generated_gallery_it.svg'

const getNotFoundPreview = (type = '') => {
  let preview

  if (typeof lang !== 'undefined' && lang === 'de') {
    if (type === 'broken') preview = brokenFileGalleryDe
    else if (type === 'generated') preview = previewGeneratedGalleryDe
    else preview = notFoundGalleryDe
  } else if (typeof lang !== 'undefined' && lang === 'it') {
    if (type === 'broken') preview = brokenFileGalleryIt
    else if (type === 'generated') preview = previewGeneratedGalleryIt
    else preview = notFoundGalleryIt
  } else if (type === 'broken') preview = brokenFileGalleryEn
  else if (type === 'generated') preview = previewGeneratedGalleryEn
  else preview = notFoundGalleryEn

  return preview
}

export const isBrokenImage = (status) => parseInt(status, 10) === IMAGE_STATUSES.broken

export const notFoundPreview = getNotFoundPreview()

export const brokenFilePreview = getNotFoundPreview('broken')

export const addClass = (el, className) => {
  if (!el || !className) return

  if (className.trim()) {
    if (el.classList) el.classList.add(className)
    // eslint-disable-next-line no-param-reassign
    else el.className += ` ${className}`
  }
}

export const hasClass = (el, className) => {
  if (!el || !className) return false

  return el.classList.contains(className)
}

export const removeClass = (el, className) => {
  if (!el || !className) return
  if (el.classList.contains(className)) el.classList.remove(className)
}

export const prepareComment = (comment) => comment.replace(/&#39;/g, '\'')

// this rounding is used, to place image/layers in fullscreen view "as precise as possible"
export const parseFloatRound = (value, decimals = 2) => {
  const num = parseFloat(value)
  if (Number.isNaN(num)) return 0
  return parseFloat(num.toFixed(decimals))
}

export const parseFloatWithComma = (value) => parseFloat(value.replace(/,/g, '.').replace(/\.(?=.*\.)/g, ''))

export const dragElement = (element, isSpaceRequired = false) => {
  let isSpaceKeyDown = false
  let cursorX = 0
  let cursorY = 0
  let dx = 0
  let dy = 0

  const updateCursorStyle = (style = '') => {
    // eslint-disable-next-line no-param-reassign
    element.style.cursor = style
  }

  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      isSpaceKeyDown = true
      updateCursorStyle('grab')
    }
  }

  const handleKeyUp = (e) => {
    if (e.key === ' ') {
      isSpaceKeyDown = false
      updateCursorStyle('')
    }
  }

  const elementDrag = (e) => {
    if (isSpaceRequired && !isSpaceKeyDown) return
    e.preventDefault()

    // Calculate the new cursor position:
    dx = cursorX - e.clientX
    dy = cursorY - e.clientY
    cursorX = e.clientX
    cursorY = e.clientY

    // eslint-disable-next-line no-param-reassign
    element.style.top = `${element.offsetTop - dy}px`
    // eslint-disable-next-line no-param-reassign
    element.style.left = `${element.offsetLeft - dx}px`
  }

  const closeDragElement = () => {
    // Stop dragging when the mouse button is released:
    document.onmouseup = null
    document.onmousemove = null
  }

  // eslint-disable-next-line no-param-reassign
  element.onmouseenter = () => {
    if (!isSpaceRequired) updateCursorStyle('grab')
  }

  // eslint-disable-next-line no-param-reassign
  element.onmouseleave = () => {
    if (!isSpaceRequired) updateCursorStyle('')
  }

  document.onkeydown = handleKeyDown
  document.onkeyup = handleKeyUp

  return (e) => {
    if (isSpaceRequired && !isSpaceKeyDown) return
    e.preventDefault()

    // Get the mouse cursor position at startup:
    cursorX = e.clientX
    cursorY = e.clientY

    document.onmouseup = closeDragElement
    document.onmousemove = elementDrag
  }
}

export const useEventListener = (eventName, handler, element = document) => {
  const savedHandler = useRef()

  useEffect(() => {
    savedHandler.current = handler
  }, [
    handler,
  ])

  useEffect(() => {
    if (!element || !element.addEventListener) {
      return () => {
      }
    }

    const eventListener = (event) => savedHandler.current(event)

    element.addEventListener(eventName, eventListener)

    return () => element.removeEventListener(eventName, eventListener)
  }, [
    eventName,
    element,
  ])

  return savedHandler
}

export const removeAllChildren = (el) => {
  while (el.firstChild) {
    el.removeChild(el.lastChild)
  }
}

export const getPathColor = (index) => {
  if (index < PATH_COLORS.length) return PATH_COLORS[index]
  return PATH_COLORS[index % PATH_COLORS.length]
}

export const getGridColor = (index) => {
  if (index < GRID_COLORS.length) return GRID_COLORS[index]
  return GRID_COLORS[index % GRID_COLORS.length]
}

export const getStrokeWidth = (width, containerWidth, isShapeDrawing = false) => {
  let strokeWidth = parseFloatRound(width / containerWidth / STROKE_WIDTH_RATIO)
  if (isShapeDrawing) strokeWidth *= SHAPE_STROKE_WIDTH_RATIO // make shape drawing thicker
  if (strokeWidth < 1) strokeWidth = 1
  return strokeWidth
}

export const updateVerticalGridLine = (lineElement, value, width, height, strokeWidth) => {
  let val
  if (Math.sign(value) !== -1) val = (value < 1 ? value * width : value)
  else val = width + value

  lineElement.setAttribute('x1', val)
  lineElement.setAttribute('y1', '0')
  lineElement.setAttribute('x2', val)
  lineElement.setAttribute('y2', height)
  lineElement.setAttribute('stroke-width', strokeWidth)
}

export const updateHorizontalGridLine = (lineElement, value, width, height, strokeWidth) => {
  let val
  if (Math.sign(value) !== -1) val = (value < 1 ? value * height : value)
  else val = height + value

  lineElement.setAttribute('x1', '0')
  lineElement.setAttribute('y1', val)
  lineElement.setAttribute('x2', width)
  lineElement.setAttribute('y2', val)
  lineElement.setAttribute('stroke-width', strokeWidth)
}

export const buildGridLine = (
  value,
  width,
  height,
  containerWidth,
  strokeWidth,
  color,
  isVertical = false,
) => {
  const lineElement = document.createElementNS('http://www.w3.org/2000/svg', 'line')

  if (isVertical) {
    lineElement.setAttribute('class', VERTICAL_GRID_CLASS)
    updateVerticalGridLine(lineElement, value, width, height, containerWidth)
  } else {
    lineElement.setAttribute('class', HORIZONTAL_GRID_CLASS)
    updateHorizontalGridLine(lineElement, value, width, height, containerWidth)
  }

  lineElement.setAttribute('stroke', color)
  if (strokeWidth) lineElement.setAttribute('stroke-width', strokeWidth)
  else lineElement.removeAttribute('stroke-width')

  return lineElement
}

export const setSvgSizeAndViewBox = (svgElement, width, height) => {
  svgElement.setAttribute('width', width)
  svgElement.setAttribute('height', height)
  svgElement.setAttribute('viewBox', `0 0 ${width} ${height}`)
}

export const buildGrids = (
  grid,
  grids,
  width,
  height,
  containerWidth,
  containerHeight,
  strokeWidth,
  color,
  className,
) => {
  const gridElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  gridElement.setAttribute('class', `${className} grid-${grids?.findIndex((g) => g.name === grid.name)}`)
  gridElement.setAttribute('style', `width: ${containerWidth}px; height: ${containerHeight}px;`)
  gridElement.dataset.sizes = `width: ${containerWidth}px; height: ${containerHeight}px;`
  setSvgSizeAndViewBox(gridElement, width, height)

  grid?.left?.forEach((left) => {
    const lineElement = buildGridLine(
      left,
      width,
      height,
      containerWidth,
      strokeWidth,
      color,
      true,
    )
    gridElement.appendChild(lineElement)
  })

  grid?.top?.forEach((top) => {
    const lineElement = buildGridLine(
      top,
      width,
      height,
      containerWidth,
      strokeWidth,
      color,
      false,
    )
    gridElement.appendChild(lineElement)
  })

  return gridElement
}

export const buildPath = (id, className, elementString, width, height, strokeWidth, pathName) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(elementString, 'text/html')

  const svgElement = doc.body.firstChild
  svgElement.setAttribute('id', id)
  svgElement.setAttribute('class', className)
  svgElement.setAttribute('style', `width: ${width}px; height: ${height}px;`)
  svgElement.dataset.pathName = pathName
  svgElement.dataset.viewBox = svgElement.getAttribute('viewBox') // will be used to get initial viewBox

  const pathElement = svgElement.getRootNode()?.querySelector('path')
  pathElement?.setAttribute('stroke', JSON.parse(localStorage.getItem('pathsColors'))[pathName])
  pathElement?.setAttribute('stroke-width', strokeWidth)

  return svgElement
}

export const getImageCount = (
  imageType,
  inputCount,
  outputCount,
  compareCount,
) => {
  if (imageType === IMAGE_TYPES.input) return inputCount
  if (imageType === IMAGE_TYPES.output) return outputCount
  if (imageType === IMAGE_TYPES.compare) return compareCount
  return 0
}

export const isAllImagesLoaded = (
  imageType,
  imagesCount,
  inputCount = 0,
  outputCount = 0,
  compareCount = 0,
) => {
  const isAllLoaded = ((imageType === IMAGE_TYPES.input && imagesCount >= inputCount)
    || (imageType === IMAGE_TYPES.output && imagesCount >= outputCount)
    || (imageType === IMAGE_TYPES.compare && imagesCount >= compareCount))

  if (!isAllLoaded) {
    if (imageType === IMAGE_TYPES.input) {
      log('isAllLoaded', isAllLoaded, 'imagesCount', imagesCount, 'inputCount', inputCount)
    } else if (imageType === IMAGE_TYPES.output) {
      log('isAllLoaded', isAllLoaded, 'imagesCount', imagesCount, 'outputCount', outputCount)
    } else if (imageType === IMAGE_TYPES.compare) {
      log('isAllLoaded', isAllLoaded, 'imagesCount', imagesCount, 'compareCount', compareCount)
    }
  }

  return isAllLoaded
}

export const createArrowLine = (x1, y1, x2, y2, stroke, strokeWidth) => {
  const line = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'line',
  )
  line.setAttribute('x1', x1)
  line.setAttribute('y1', y1)
  line.setAttribute('x2', x2)
  line.setAttribute('y2', y2)
  line.setAttribute('stroke', stroke)
  line.setAttribute('stroke-width', strokeWidth)
  return line
}

export const drawArrowhead = (x1, y1, x2, y2, stroke, strokeWidth) => {
  const arrowLength = 8 * strokeWidth
  const arrowAngle = Math.atan2(y2 - y1, x2 - x1)

  const adjustedX2 = x2 - strokeWidth * Math.cos(arrowAngle) * 0.25
  const adjustedY2 = y2 - strokeWidth * Math.sin(arrowAngle) * 0.25

  const arrowPoint1 = {
    x: adjustedX2 - arrowLength * Math.cos(arrowAngle - Math.PI / 6),
    y: adjustedY2 - arrowLength * Math.sin(arrowAngle - Math.PI / 6),
  }
  const arrowPoint2 = {
    x: adjustedX2 - arrowLength * Math.cos(arrowAngle + Math.PI / 6),
    y: adjustedY2 - arrowLength * Math.sin(arrowAngle + Math.PI / 6),
  }

  return [
    createArrowLine(adjustedX2, adjustedY2, arrowPoint1.x, arrowPoint1.y, stroke, strokeWidth),
    createArrowLine(adjustedX2, adjustedY2, arrowPoint2.x, arrowPoint2.y, stroke, strokeWidth),
  ]
}

export const appendShapes = (shapes, strokeWidth, container) => {
  shapes.forEach((shapeData) => {
    let shapeElement

    switch (shapeData.type) {
      case 'line':
      case 'arrow':
        shapeElement = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'line',
        )
        shapeElement.setAttribute('x1', shapeData.x1)
        shapeElement.setAttribute('y1', shapeData.y1)
        shapeElement.setAttribute('x2', shapeData.x2)
        shapeElement.setAttribute('y2', shapeData.y2)
        shapeElement.setAttribute('stroke', shapeData.stroke)
        shapeElement.setAttribute('stroke-width', strokeWidth)
        if (shapeData.type === 'arrow') {
          const [arrowHeadLine1, arrowHeadLine2] = drawArrowhead(
            shapeData.x1,
            shapeData.y1,
            shapeData.x2,
            shapeData.y2,
            shapeData.stroke,
            strokeWidth,
          )
          container.appendChild(arrowHeadLine1)
          container.appendChild(arrowHeadLine2)
        }
        break
      case DEFAULT_DRAWING_TYPE:
        shapeElement = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'ellipse',
        )
        shapeElement.setAttribute('cx', shapeData.cx)
        shapeElement.setAttribute('cy', shapeData.cy)
        shapeElement.setAttribute('rx', shapeData.rx)
        shapeElement.setAttribute('ry', shapeData.ry)
        shapeElement.setAttribute('stroke', shapeData.stroke)
        shapeElement.setAttribute('stroke-width', strokeWidth)
        shapeElement.setAttribute('fill', 'none')
        break
      case 'rectangular':
        shapeElement = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'rect',
        )
        shapeElement.setAttribute('x', shapeData.x)
        shapeElement.setAttribute('y', shapeData.y)
        shapeElement.setAttribute('width', shapeData.width)
        shapeElement.setAttribute('height', shapeData.height)
        shapeElement.setAttribute('stroke', shapeData.stroke)
        shapeElement.setAttribute('stroke-width', strokeWidth)
        shapeElement.setAttribute('fill', 'none')
        break
      case 'freehand':
        shapeElement = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'path',
        )
        shapeElement.setAttribute('d', shapeData.d)
        shapeElement.setAttribute('stroke', shapeData.stroke)
        shapeElement.setAttribute('stroke-width', strokeWidth)
        shapeElement.setAttribute('fill', 'none')
        break
      default:
        break
    }

    if (shapeElement) container.appendChild(shapeElement)
  })
}

export const getContrastColor = (hexColor = GALLERY_BG_DEFAULT) => {
  // if (['#ffffff', '#fff', '#000000', '#000'].includes(hexColor.toLowerCase())) return DEFAULT_TEXT_COLOR
  if (['#ffffff', '#fff'].includes(hexColor.toLowerCase())) return DEFAULT_TEXT_COLOR

  // Remove the hash at the start if it's there
  const hex = hexColor.replace(/^#/, '')

  // Parse the hex color into its R, G, and B components
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255

  return luminance > 0.5 ? '#000000' : '#FFFFFF'
}

export const scrollToBottom = (el, timeout = 10) => {
  if (!el) return
  setTimeout(() => {
    // eslint-disable-next-line no-param-reassign
    el.scrollTop = el.scrollHeight
  }, timeout)
}

export const getPickerColor = (customColorType) => localStorage.getItem(customColorType) || '#FFFFFF'

export const getIsExpanded = () => (localStorage.getItem('isExpanded')
  ? JSON.parse(localStorage.getItem('isExpanded'))
  : DEFAULT_IS_EXPANDED)

export const removeErrorClasses = (image, element, errorCode) => {
  if (errorCode === IMAGE_ERROR_CODES.wrongFileName) {
    removeClass(element, IMAGE_ERROR_CLASS)
    if (!image.wrong_format) removeClass(element.parentElement, INFO_ERROR_CLASS)
  } else if (errorCode === IMAGE_ERROR_CODES.wrongFormat) {
    removeClass(element, IMAGE_ERROR_CLASS)
    if (!image.wrong_file_name) removeClass(element.parentElement, INFO_ERROR_CLASS)
  } else if (errorCode === IMAGE_ERROR_CODES.colorSpaceError || errorCode === IMAGE_ERROR_CODES.noBgColor) {
    removeClass(element, IMAGE_ERROR_CLASS)
    removeClass(element.parentElement, INFO_ERROR_CLASS)
  } else if (errorCode === IMAGE_ERROR_CODES.widthError) {
    removeClass(element, IMAGE_ERROR_CLASS)
    if (!image.height_error && !image.dpi_error) removeClass(element.parentElement, INFO_ERROR_CLASS)
  } else if (errorCode === IMAGE_ERROR_CODES.heightError) {
    removeClass(element, IMAGE_ERROR_CLASS)
    if (!image.width_error && !image.dpi_error) removeClass(element.parentElement, INFO_ERROR_CLASS)
  } else if (errorCode === IMAGE_ERROR_CODES.dpiError) {
    removeClass(element, IMAGE_ERROR_CLASS)
    if (!image.width_error && !image.height_error) removeClass(element.parentElement, INFO_ERROR_CLASS)
  } else {
    removeClass(element, IMAGE_ERROR_CLASS)
  }
}

export const formatFilesize = (sizeKb) => {
  if (!sizeKb) return `0 ${Translation.kb}`
  return sizeKb > 1024
    ? `${(sizeKb / 1024).toFixed(2)} ${Translation.mb}`
    : `${sizeKb} ${Translation.kb}`
}

export const getPathErrorCode = (pathIndex) => {
  const code = IMAGE_ERROR_CODES.pathError + pathIndex + 1
  return Math.min(code, (IMAGE_ERROR_CODES.pathErrorMissing - 1))
}

export const getLayerErrorCode = (layerIndex) => {
  const code = IMAGE_ERROR_CODES.layerError + layerIndex + 1
  return Math.min(code, (IMAGE_ERROR_CODES.layerErrorMissing - 1))
}

export const isDownloadable = (storageClass) => typeof storageClass === 'undefined'
export const isArchived = (storageClass) => storageClass === 'GLACIER' || storageClass === 'DEEP_ARCHIVE'
export const isRestoringInProgress = (restore) => restore === 'ongoing-request="true"'
export const isRestorable = (restore) => restore === ''

export const getMissingPaths = (image, orderActionValues = []) => {
  const orderAction = orderActionValues[0] || {}
  const missingPaths = [
    orderAction.clipping_path1_name,
    orderAction.clipping_path2_name,
    orderAction.clipping_path3_name,
    orderAction.clipping_path4_name,
    orderAction.clipping_path5_name,
    orderAction.clipping_path6_name,
    orderAction.clipping_path7_name,
    orderAction.clipping_path8_name,
    orderAction.clipping_path9_name,
    orderAction.clipping_path10_name,
  ].filter(Boolean).filter((path) => !Object.keys(image.path).includes(path))

  return missingPaths.join(', ')
}

export const getMissingLayers = (image, orderActionValues = []) => {
  const orderAction = orderActionValues[0] || {}
  const missingLayers = [
    orderAction.clipping_separate1_name,
    orderAction.clipping_separate2_name,
    orderAction.clipping_separate3_name,
    orderAction.clipping_separate4_name,
    orderAction.clipping_separate5_name,
    orderAction.clipping_separate6_name,
    orderAction.clipping_separate7_name,
    orderAction.clipping_separate8_name,
    orderAction.clipping_separate9_name,
    orderAction.clipping_separate10_name,
  ].filter(Boolean).filter((layer) => !image.layer.some((l) => l.name === layer))

  return missingLayers.join(', ')
}
