import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  IMAGE_ERROR_CLASS,
  IMAGE_ERROR_CODES,
  POINTER_CLASS,
} from '../../../../../helpers/Constants'
import { Translation } from '../../../../../helpers/Translation'
import { isAltOrCommandKey } from '../../../../../helpers/Utils'

const ImageColorSpace = ({
  isOriginalImage,
  originalImageDetails,
  image,
  removeErrorCode,
}) => {
  const [isPointerActive, setPointerActive] = useState(false)

  const handleMouseMove = (e) => {
    setPointerActive(isAltOrCommandKey(e))
  }

  const hasError = isOriginalImage ? originalImageDetails?.color_space_error : image.color_space_error

  return (
    <div className={hasError ? 'info info-error' : 'info'}>
      <span
        className={hasError ? `${IMAGE_ERROR_CLASS} ${isPointerActive ? POINTER_CLASS : ''}` : ''}
        onMouseMove={handleMouseMove}
        onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.colorSpaceError)}
      >
        {isOriginalImage
          ? (originalImageDetails?.colour_space || Translation.no_colour_space)
          : (image.colour_space || Translation.no_colour_space)}
      </span>
    </div>
  )
}

ImageColorSpace.propTypes = {
  isOriginalImage: PropTypes.bool.isRequired,
  originalImageDetails: PropTypes.shape({
    color_space_error: PropTypes.bool,
    colour_space: PropTypes.string,
  }),
  image: PropTypes.shape({
    color_space_error: PropTypes.bool,
    colour_space: PropTypes.string,
  }).isRequired,
  removeErrorCode: PropTypes.func.isRequired,
}

ImageColorSpace.defaultProps = {
  originalImageDetails: {
    color_space_error: false,
    colour_space: '',
  },
}

export default ImageColorSpace
