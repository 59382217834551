import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  IMAGE_ERROR_CLASS,
  IMAGE_ERROR_CODES,
  POINTER_CLASS,
} from '../../../../../helpers/Constants'
import { Translation } from '../../../../../helpers/Translation'
import { formatFilesize, isAltOrCommandKey } from '../../../../../helpers/Utils'

const ImageDimensions = ({
  isOriginalImage,
  originalImageDetails,
  image,
  removeErrorCode,
}) => {
  const [isPointerActive, setPointerActive] = useState(false)

  const handleMouseMove = (e) => {
    setPointerActive(isAltOrCommandKey(e))
  }

  const getClassName = (error) => (error ? `${IMAGE_ERROR_CLASS} ${isPointerActive ? POINTER_CLASS : ''}` : '')

  return (
    <div
      className={
        (isOriginalImage
          ? (originalImageDetails?.width_error || originalImageDetails?.height_error || originalImageDetails?.dpi_error)
          : (image.width_error || image.height_error || image.dpi_error))
          ? 'info info-error'
          : 'info'
      }
    >
      <span
        className={getClassName(isOriginalImage ? originalImageDetails?.width_error : image.width_error)}
        onMouseMove={handleMouseMove}
        onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.widthError)}
      >
        {isOriginalImage ? originalImageDetails?.width : image.width}
      </span>
      <span>
        {' '}
        x
        {' '}
      </span>
      <span
        className={getClassName(isOriginalImage ? originalImageDetails?.height_error : image.height_error)}
        onMouseMove={handleMouseMove}
        onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.heightError)}
      >
        {isOriginalImage ? originalImageDetails?.height : image.height}
      </span>
      <span>
        {' '}
        {Translation.px}
        {' '}
        /
        {' '}
      </span>
      <span
        className={getClassName(isOriginalImage ? originalImageDetails?.dpi_error : image.dpi_error)}
        onMouseMove={handleMouseMove}
        onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.dpiError)}
      >
        {isOriginalImage ? originalImageDetails?.dpi || 0 : image.dpi || 0}
      </span>
      <span>
        {' '}
        {Translation.dpi}
        {' '}
        /
        {' '}
        {formatFilesize(isOriginalImage ? originalImageDetails?.size_kb : image.size_kb)}
      </span>
    </div>
  )
}

ImageDimensions.propTypes = {
  isOriginalImage: PropTypes.bool,
  originalImageDetails: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    dpi: PropTypes.string,
    size_kb: PropTypes.string,
    width_error: PropTypes.bool,
    height_error: PropTypes.bool,
    dpi_error: PropTypes.bool,
  }),
  image: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    dpi: PropTypes.string,
    size_kb: PropTypes.string,
    width_error: PropTypes.bool,
    height_error: PropTypes.bool,
    dpi_error: PropTypes.bool,
  }).isRequired,
  removeErrorCode: PropTypes.func.isRequired,
}

ImageDimensions.defaultProps = {
  isOriginalImage: false,
  originalImageDetails: {},
}

export default ImageDimensions
