import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'

import { axiosInstance as axios } from '../helpers/Axios'
import { API_ENDPOINTS } from '../helpers/Urls'
import {
  CLIENT_GALLERY_PREFIX,
  ADMIN_GALLERY_PREFIX,
  CLIENT_LIST_PREFIX,
  ADMIN_LIST_PREFIX,
} from '../helpers/Constants'
import { isLocal } from '../helpers/Cookie'
import { Translation } from '../helpers/Translation'

import Layout from '../layouts/Layout'

import Gallery from '../pages/Gallery'
import List from '../pages/List'
import Home from '../pages/Home'
import ErrorJs from '../pages/ErrorJs'
import Error from '../pages/Error'

const logJsErrorToApi = (error, info) => {
  if (isLocal) return

  axios.post(API_ENDPOINTS.jsError, {
    message: error.message,
    file: error?.fileName,
    line: error?.lineNumber,
    column: error?.columnNumber,
    stack: info.componentStack,
    userAgent: navigator.userAgent,
    href: window.location.href,
    isFromReact: true,
  })
}

const pages = [
  {
    path: '/',
    element: (
      <Home />
    ),
  },
  {
    path: `${CLIENT_GALLERY_PREFIX}/:image_type/:order_id`,
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Layout
          pageName="gallery"
          title={Translation.gallery}
        >
          <Gallery />
        </Layout>
      </ErrorBoundary>
    ),
  },
  {
    path: `${CLIENT_GALLERY_PREFIX}/:image_type/:order_id/:image_size`,
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Layout
          pageName="gallery"
          title={Translation.gallery}
        >
          <Gallery />
        </Layout>
      </ErrorBoundary>
    ),
  },
  {
    path: `${CLIENT_GALLERY_PREFIX}/:image_type/:order_id/:image_size/:image_sort`,
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Layout
          pageName="gallery"
          title={Translation.gallery}
        >
          <Gallery />
        </Layout>
      </ErrorBoundary>
    ),
  },
  {
    path: `${CLIENT_LIST_PREFIX}/:image_type/:order_id`,
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Layout
          pageName="gallery_list"
          title={Translation.gallery}
        >
          <List />
        </Layout>
      </ErrorBoundary>
    ),
  },
  {
    path: `${ADMIN_GALLERY_PREFIX}/:image_type/:order_id`,
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Layout
          pageName="admin_gallery"
          title={Translation.gallery}
        >
          <Gallery />
        </Layout>
      </ErrorBoundary>
    ),
  },
  {
    path: `${ADMIN_GALLERY_PREFIX}/:image_type/:order_id/:image_size`,
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Layout
          pageName="admin_gallery"
          title={Translation.gallery}
        >
          <Gallery />
        </Layout>
      </ErrorBoundary>
    ),
  },
  {
    path: `${ADMIN_GALLERY_PREFIX}/:image_type/:order_id/:image_size/:image_sort`,
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Layout
          pageName="admin_gallery"
          title={Translation.gallery}
        >
          <Gallery />
        </Layout>
      </ErrorBoundary>
    ),
  },
  {
    path: `${ADMIN_GALLERY_PREFIX}/:image_type/:order_id/:image_size/:image_sort/:is_combine_explode`,
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Layout
          pageName="admin_gallery"
          title={Translation.gallery}
        >
          <Gallery />
        </Layout>
      </ErrorBoundary>
    ),
  },
  {
    path: `${ADMIN_LIST_PREFIX}/:image_type/:order_id`,
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Layout
          pageName="admin_gallery_list"
          title={Translation.gallery}
        >
          <List />
        </Layout>
      </ErrorBoundary>
    ),
  },
  {
    pageName: 'error',
    path: '*',
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Error
          errorType="404"
          errorTitle={Translation.error_not_found}
          errorDescr={Translation.page_not_exist}
          btnLabel={Translation.back_to_doopic}
        />
      </ErrorBoundary>
    ),
  },
]

export const Router = createBrowserRouter([
  ...pages,
])
