import React, { useEffect, useRef, useState } from 'react'
import { useStoreState } from 'easy-peasy'
import { MY_DOOPIC_URLS } from '../../../helpers/Urls'
import { HIDE_DROPDOWN_TIMEOUT } from '../../../helpers/Constants'
import { Translation } from '../../../helpers/Translation'

import { ReactComponent as SignOutSvg } from '../../../svg/sign_out.svg'

import './index.scss'

const ProfileMenu = () => {
  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const isMouseOverRef = useRef(false)
  const isOpenedRef = useRef(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [imageError, setImageError] = useState(false)

  useEffect(() => {
    isOpenedRef.current = isMenuOpen
  }, [
    isMenuOpen,
  ])

  const onHandleMenuToggle = (e) => {
    e.stopPropagation()

    setIsMenuOpen(!isMenuOpen)
  }

  const onHandleMouseLeave = () => {
    isMouseOverRef.current = false

    setTimeout(() => {
      if (isOpenedRef.current && !isMouseOverRef.current) {
        setIsMenuOpen(false)
      }
    }, HIDE_DROPDOWN_TIMEOUT)
  }

  const onHandleMouseEnter = () => {
    isMouseOverRef.current = true
  }

  return (
    <div
      className="header--filters__account"
      style={{
        backgroundColor: userState.user.avatar_bg || '',
      }}
      onClick={onHandleMenuToggle}
      onMouseLeave={onHandleMouseLeave}
      onMouseEnter={onHandleMouseEnter}
    >
      {imageError || !userState.user.avatar_url ? (
        <a href={MY_DOOPIC_URLS.profile}>
          {userState.user.nickname}
        </a>
      ) : (
        <img
          src={userState.user.avatar_url}
          alt={userState.user.firstname}
          className="profile-picture"
          onError={() => setImageError(true)}
        />
      )}

      <div
        className="account_menu"
        style={{ display: isMenuOpen ? '' : 'none' }}
      >
        <div className="account-item active status">
          <div className="status-color green" />
          <span>{Translation.active}</span>
        </div>
        <div className="account-item status">
          <div className="status-color orange" />
          <span>{Translation.break}</span>
        </div>
        <div className="account-item status">
          <div className="status-color red" />
          <span>{Translation.checkout}</span>
        </div>
        <div className="account-item log-out">
          <SignOutSvg />
          <span>{Translation.sign_out}</span>
        </div>
      </div>
    </div>
  )
}

export default ProfileMenu
