import React, { useEffect, useRef, useState } from 'react'
import { useStoreState } from 'easy-peasy'
import PropTypes from 'prop-types'

import { Translation } from '../../../helpers/Translation'
import {
  IMAGE_PREVIEWS_WIDTHS,
  IMAGE_TYPES,
  IMAGES_PER_PAGE,
  SKELETON_CLASS,
} from '../../../helpers/Constants'
import { MY_DOOPIC_URLS } from '../../../helpers/Urls'
import {
  getContrastColor,
  getImageCount,
  getPreviewWidth,
} from '../../../helpers/Utils'

import Preview from './Preview'

import { ReactComponent as ScissorSvg } from '../../../svg/scissor.svg'
import { ReactComponent as MagnifierSvg } from '../../../svg/magnifier.svg'
import { ReactComponent as LoaderSvg } from '../../../svg/loader.svg'

import './index.scss'

const Previews = ({ refreshGallery, resetInterval }) => {
  const orderState = useStoreState((state) => ({
    filteredImages: state.order.filteredImages,
    imageType: state.order.imageType,
    imageSize: state.order.imageSize,
    gallery: state.order.gallery,
    isImagesLoading: state.order.isImagesLoading,
    galleryBackground: state.order.galleryBackground,
  }))

  const [textColor, setTextColor] = useState('#FFFFFF')
  const [skeletonCount, setSkeletonCount] = useState(0)
  const [imageCount, setImageCount] = useState(0)
  const [previewWidth, setPreviewWidth] = useState(IMAGE_PREVIEWS_WIDTHS.small)
  const [showEmptyResult, setShowEmptyResult] = useState(false)

  const emptyResultRef = useRef(null)

  useEffect(() => {
    if (!orderState.filteredImages && !orderState.isImagesLoading) {
      const timer = setTimeout(() => {
        setShowEmptyResult(true)
      }, 500)
      return () => clearTimeout(timer)
    }
    return () => {
    }
  }, [
    orderState.filteredImages,
    orderState.isImagesLoading,
  ])

  useEffect(() => {
    const count = getImageCount(
      orderState.imageType,
      orderState.gallery?.input_count,
      orderState.gallery?.output_count,
      orderState.gallery?.compare_count,
    )

    setImageCount(count)

    if (count) {
      setSkeletonCount(count < IMAGES_PER_PAGE ? count : IMAGES_PER_PAGE)
    }
  }, [
    orderState.gallery?.input_count,
    orderState.gallery?.output_count,
    orderState.gallery?.compare_count,
    orderState.imageType,
  ])

  useEffect(() => {
    setTextColor(getContrastColor(orderState.galleryBackground))
  }, [
    orderState.galleryBackground,
  ])

  useEffect(() => {
    setPreviewWidth(getPreviewWidth(orderState.imageSize))
  }, [
    orderState.imageSize,
  ])

  return (
    // eslint-disable-next-line no-nested-ternary
    (!orderState.filteredImages && orderState.isImagesLoading) ? (
      skeletonCount > 0 ? (
        <>
          {[...Array(skeletonCount)].map((_, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`skeleton-row-${index}`}
              className="gallery-image--wrap"
              style={{ width: `${previewWidth}px`, height: `${previewWidth * 1.5 + 100}px` }}
            >
              <div
                className={`image-preview--bg ${SKELETON_CLASS}`}
                style={{ width: `${previewWidth}px`, height: `${previewWidth * 1.5 + 100}px` }}
              />
              <div className="image-preview--details">
                <div className="image-preview--details__filename">
                  <span className="image-preview--details__filename--filename">
                    <div
                      className={SKELETON_CLASS}
                      style={{
                        width: '100px',
                        height: '10px',
                        marginBottom: '10px',
                        marginTop: '5px',
                      }}
                    />
                  </span>
                </div>
                <div className="image-preview--details__sizes--wrap">
                  <div className="image-preview--details__sizes">
                    <div
                      className={SKELETON_CLASS}
                      style={{
                        width: '150px',
                        height: '10px',
                        marginBottom: '10px',
                      }}
                    />
                  </div>
                  <div className="image-preview--details__colour-space">
                    <div
                      className={SKELETON_CLASS}
                      style={{
                        width: '140px',
                        height: '10px',
                        marginBottom: '10px',
                      }}
                    />
                  </div>
                  <div className="image-preview--details__bg-colour">
                    <div
                      className={SKELETON_CLASS}
                      style={{
                        width: '120px',
                        height: '10px',
                        marginBottom: '10px',
                      }}
                    />
                  </div>
                  <div className="image-preview--details__colour-depth">
                    <div
                      className={SKELETON_CLASS}
                      style={{
                        width: '110px',
                        height: '10px',
                        marginBottom: '10px',
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className="loader--wrap">
          <LoaderSvg color={getContrastColor(orderState.galleryBackground)} />
        </div>
      )
    ) : (
      // eslint-disable-next-line no-nested-ternary
      orderState.gallery ? (
        // eslint-disable-next-line no-nested-ternary
        orderState.filteredImages && orderState.filteredImages.length > 0 ? (
          <>
            {orderState.filteredImages.map((image, index) => (
              <Preview
                key={image.id}
                image={image}
                index={index}
                refreshGallery={refreshGallery}
                resetInterval={resetInterval}
              />
            ))}
          </>
        ) : (
          !imageCount ? (
            <div className="empty-result">
              <ScissorSvg
                style={{ color: textColor }}
              />
              {orderState.imageType === IMAGE_TYPES.input ? (
                <h6
                  className="h6 m-top-5"
                  style={{ color: textColor }}
                >
                  {Translation.sorry_no_images_found}
                </h6>
              ) : (
                <h6
                  className="h6 m-top-5"
                  style={{ color: textColor }}
                >
                  {Translation.no_output_images_found_1}
                  <br />
                  {Translation.no_output_images_found_2}
                </h6>
              )}
            </div>
          ) : null
        )
      ) : (
        <div
          ref={emptyResultRef}
          className="empty-result"
          style={{ display: showEmptyResult ? 'block' : 'none' }}
        >
          <MagnifierSvg
            style={{ color: textColor }}
          />
          <h6
            className="h6 m-top-5"
            style={{ color: textColor }}
          >
            {Translation.we_could_not_find_order}
            {' '}
            <a
              href={MY_DOOPIC_URLS.support}
              target="_blank"
              rel="noreferrer"
              style={{ borderBottomColor: textColor }}
            >
              {Translation.support}
            </a>
            {' '}
            {Translation.for_assistance}
            .
          </h6>
        </div>
      )
    )
  )
}

Previews.propTypes = {
  refreshGallery: PropTypes.func,
  resetInterval: PropTypes.func,
}

Previews.defaultProps = {
  refreshGallery: () => {
  },
  resetInterval: () => {
  },
}

export default Previews
