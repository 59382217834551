import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { COMMENT_STATUSES, COMMENT_TYPES, IMAGE_SIZES } from '../../../helpers/Constants'
import { Translation } from '../../../helpers/Translation'
import { API_ENDPOINTS_GALLERY } from '../../../helpers/Urls'
import { getEndpointUrl, getIsExpanded, prepareComment } from '../../../helpers/Utils'

import { ReactComponent as CrossSvg } from '../../../svg/action_cross.svg'
import { ReactComponent as CommentRejectedSvg } from '../../../svg/comment_rejected.svg'

import './index.scss'

const Comment = ({
  comment,
  isLast,
  refreshGallery,
  resetInterval,
  isInputInOutput,
}) => {
  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const orderState = useStoreState((state) => ({
    selectedImages: state.order.selectedImages,
    orderId: state.order.orderId,
    imageType: state.order.imageType,
  }))

  const orderActions = useStoreActions((actions) => ({
    imageAction: actions.order.imageAction,
    getImageDetails: actions.order.getImageDetails,
  }))

  const [isExpanded] = useState(getIsExpanded())

  const handleCommentClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleCommentDelete = async (e) => {
    e.stopPropagation()
    const commentEl = e.currentTarget?.parentNode

    const res = await orderActions.imageAction({
      url: getEndpointUrl(userState.user.is_admin, API_ENDPOINTS_GALLERY.commentDelete),
      body: {
        comment_ids: [parseInt(comment.id, 10)],
      },
    })

    if (res.success) {
      if (commentEl) commentEl.style.display = 'none' // hide deleted comment

      resetInterval()
      // refreshGallery([imageIdForDeletedComment]) // TODO: request data only for updated images
      refreshGallery()

      // update image history
      orderActions.getImageDetails({
        image_ids: [parseInt(comment.image_id, 10)],
        order_ids: [orderState.orderId],
        image_type: orderState.imageType,
        image_size: IMAGE_SIZES.extraLarge,
        is_with_comments: isExpanded.history,
      })
    }
  }

  return (
    <>
      <div
        onClick={handleCommentClick}
        className={`image-preview--comment__wrap ${parseInt(comment.status, 10) === COMMENT_STATUSES.rejected
          ? 'image-preview--comment__wrap--deleted' : ''}`}
      >
        {comment.type === COMMENT_TYPES.qaClient && (
          <div className={`image-preview--comment__icon image-preview--comment__icon--client 
            ${parseInt(comment.status, 10) === COMMENT_STATUSES.active
            ? 'image-preview__icon--client__approved' : ''}`}
          >
            {Translation.client_short}
          </div>
        )}

        {comment.type === COMMENT_TYPES.clientQa && (
          <div className="image-preview--comment__icon image-preview--comment__icon--rejected">
            <CommentRejectedSvg />
          </div>
        )}

        <div className="image-preview--comment">
          {prepareComment(comment.comment)}
        </div>

        {!isInputInOutput && (
          <div className="image-preview--comment__delete" onClick={handleCommentDelete}>
            <CrossSvg />
          </div>
        )}
      </div>

      {!isLast && <div className="image-preview--comments__separator" />}
    </>
  )
}

Comment.propTypes = {
  comment: PropTypes.instanceOf(Object),
  isLast: PropTypes.bool,
  refreshGallery: PropTypes.func,
  resetInterval: PropTypes.func,
  isInputInOutput: PropTypes.bool,
}

Comment.defaultProps = {
  comment: {},
  isLast: false,
  refreshGallery: () => {
  },
  resetInterval: () => {
  },
  isInputInOutput: false,
}

export default Comment
