import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { parseFloatWithComma, useEventListener } from '../../../../helpers/Utils'
import { Translation } from '../../../../helpers/Translation'

import Typography from '../../../../components/Typography'
import Dialog from '../../../../components/Dialog'

import './index.scss'

const UpdatePricesDialog = ({ refreshGallery, resetInterval }) => {
  const layoutState = useStoreState((state) => ({
    isUpdatePricesModalOpened: state.layout.isUpdatePricesModalOpened,
  }))

  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const orderState = useStoreState((state) => ({
    selectedImages: state.order.selectedImages,
    gallery: state.order.gallery,
  }))

  const layoutActions = useStoreActions((actions) => ({
    updateUpdatePricesModalOpened: actions.layout.updateUpdatePricesModalOpened,
  }))

  const orderActions = useStoreActions((actions) => ({
    updatePrices: actions.order.updatePrices,
  }))

  const [prices, setPrices] = useState(
    Array(orderState.gallery?.order_action_values.length).fill(''),
  )

  const [priceErrors, setPriceErrors] = useState(
    Array(orderState.gallery?.order_action_values.length).fill(false),
  )

  const onPriceChange = (index, value) => {
    const newPrices = [...prices]
    const newPriceErrors = [...priceErrors]
    if (value) {
      newPrices[index] = value
      const regex = /^(?=.{1,6}$)[0-9]+[.,]?([0-9]{1,2})?$/
      newPriceErrors[index] = !regex.test(value)
    } else {
      newPrices[index] = ''
      newPriceErrors[index] = false
    }
    setPrices(newPrices)
    setPriceErrors(newPriceErrors)
  }

  const onUpdatePrices = async () => {
    if (priceErrors.every((error) => error === false) && prices.every((price) => price !== '')) {
      const res = await orderActions.updatePrices({
        method: 'post',
        is_admin: userState.user.is_admin,
        body: {
          image_ids: orderState.selectedImages,
          order_format_ids: orderState.gallery?.order_action_values.map((item) => item.order_format_id),
          order_format_prices: prices.map((price) => parseFloatWithComma(price)),
        },
      })

      if (res) {
        resetInterval()
        // refreshGallery(orderState.selectedImages) // TODO: request data only for updated images
        refreshGallery()

        layoutActions.updateUpdatePricesModalOpened(false)
      }
    }
  }

  const onDeletePrices = async () => {
    const res = await orderActions.updatePrices({
      method: 'delete',
      is_admin: userState.user.is_admin,
      body: {
        image_ids: orderState.selectedImages,
        order_format_ids: orderState.gallery?.order_action_values.map((item) => item.order_format_id),
      },
    })

    if (res) {
      resetInterval()
      // refreshGallery(orderState.selectedImages) // TODO: request data only for updated images
      refreshGallery()

      layoutActions.updateUpdatePricesModalOpened(false)
    }
  }

  useEventListener('keyup', (e) => {
    if (e.key === 'Enter' && layoutState.isUpdatePricesModalOpened) {
      onUpdatePrices().then(() => {
      })
    }
  })

  return (
    <Dialog
      isShown
      onClickCloseIcon={() => layoutActions.updateUpdatePricesModalOpened(false)}
      onClickOutside={() => layoutActions.updateUpdatePricesModalOpened(false)}
      isCentered
      onClickCancel={() => layoutActions.updateUpdatePricesModalOpened(false)}
      title={Translation.prices_for_selected_image}
      yesLabel={Translation.ok}
      cancelLabel={Translation.cancel}
      onClickYes={onUpdatePrices}
      content={(
        <div className="update-prices-dialog">
          {prices.map((price, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className="update-prices-dialog__price">
              <input
                type="text"
                placeholder={`${index + 1}. ${Translation.format} (${orderState.gallery?.order.currency})`}
                value={price}
                onChange={(e) => onPriceChange(index, e.target.value)}
                maxLength={6}
                max={6}
              />
              {priceErrors[index] && (
                <span className="update-prices-dialog__price-error">
                  <Typography
                    label={Translation.must_be_a_valid_amount}
                    theme="error"
                    variant="xs"
                  />
                </span>
              )}
            </div>
          ))}

          <button
            type="button"
            onClick={onDeletePrices}
            className="update-prices-dialog__delete-button"
          >
            <Typography
              label={Translation.delete_extra_prices}
              theme="link"
              variant="xs"
            />
          </button>
        </div>
      )}
    />
  )
}

UpdatePricesDialog.propTypes = {
  refreshGallery: PropTypes.func,
  resetInterval: PropTypes.func,
}

UpdatePricesDialog.defaultProps = {
  refreshGallery: () => {
  },
  resetInterval: () => {
  },
}

export default UpdatePricesDialog
