import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import PropTypes from 'prop-types'

import './index.scss'

const DualRange = ({
  min,
  max,
  values,
  onChange,
  leftLabel,
  rightLabel,
}) => {
  const [minVal, setMinVal] = useState(values.min)
  const [maxVal, setMaxVal] = useState(values.max)
  const minValRef = useRef(values.min)
  const maxValRef = useRef(values.max)
  const range = useRef(null)

  // Convert to percentage
  const getPercent = useCallback((value) => Math.round(((value - min) / (max - min)) * 100), [min, max])

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal)
    const maxPercent = getPercent(maxValRef.current)

    if (range.current) {
      range.current.style.left = `${minPercent}%`
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [
    minVal,
    getPercent,
  ])

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current)
    const maxPercent = getPercent(maxVal)

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [
    maxVal,
    getPercent,
  ])

  // Get min and max values when their state changes
  useEffect(() => {
    onChange({ min: minVal, max: maxVal })
  }, [
    minVal,
    maxVal,
    onChange,
  ])

  useEffect(() => {
    setMinVal(values.min)
    minValRef.current = values.min
    setMaxVal(values.max)
    maxValRef.current = values.max
  }, [
    values,
  ])

  return (
    <div className="doo-dual-range">
      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        onChange={(event) => {
          const value = Number(event.target.value)

          if (value > maxValRef.current) {
            setMaxVal(value)
            maxValRef.current = value
          }
          setMinVal(value)
          minValRef.current = value
        }}
        className="thumb thumb--left"
        style={{ zIndex: (minVal > (max - 100)) && '5' }}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        onChange={(event) => {
          const value = Number(event.target.value)

          if (value < minValRef.current) {
            setMinVal(value)
            minValRef.current = value
          }
          setMaxVal(value)
          maxValRef.current = value
        }}
        className="thumb thumb--right"
      />

      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
        <div className="slider__left-value">{leftLabel}</div>
        <div className="slider__right-value">{rightLabel}</div>
      </div>
    </div>
  )
}

DualRange.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  values: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  onChange: PropTypes.func.isRequired,
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
}

DualRange.defaultProps = {
  leftLabel: '',
  rightLabel: '',
  values: {
    min: 0,
    max: 255,
  },
}

export default DualRange
