import React from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { useNavigate } from 'react-router-dom'

import { Translation } from '../../../helpers/Translation'
import { ACTIVE_CLASS, DISABLED_CLASS, PX_TO_REM } from '../../../helpers/Constants'
import { getGalleryUrl, hasClass } from '../../../helpers/Utils'

import Tooltip from '../../../components/Tooltip'

import { ReactComponent as FiltersCombineSvg } from '../../../svg/filters_combine.svg'

import './index.scss'

const ToggleCombine = () => {
  const navigate = useNavigate()

  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const orderState = useStoreState((state) => ({
    isCombineExplode: state.order.isCombineExplode,
    gallery: state.order.gallery,
    imageType: state.order.imageType,
    orderId: state.order.orderId,
    imageSize: state.order.imageSize,
    imageSort: state.order.imageSort,
  }))

  const orderActions = useStoreActions((actions) => ({
    setIsCombineExplode: actions.order.setIsCombineExplode,
    resetImagesFilters: actions.order.resetImagesFilters,
  }))

  const isExplodedOrder = orderState.gallery?.is_exploded_order

  const handleCombineClick = (e) => {
    if (!orderState.gallery?.is_exploded_order) return

    let isCombineExplodeUpdate
    if (!hasClass(e.currentTarget, ACTIVE_CLASS)) isCombineExplodeUpdate = 1

    orderActions.resetImagesFilters()
    orderActions.setIsCombineExplode(isCombineExplodeUpdate)

    navigate(getGalleryUrl(
      userState.user.is_admin,
      orderState.imageType,
      orderState.orderId,
      orderState.imageSize,
      orderState.imageSort,
      isCombineExplodeUpdate,
    ))
  }

  return (
    <Tooltip
      text={isExplodedOrder
        ? Translation.all_images_of_exploded_order
        : Translation.order_has_no_child_orders}
      position="bottom"
      offset={PX_TO_REM['51']}
    >
      <div
        className={`header--filters__combine 
          ${!isExplodedOrder ? DISABLED_CLASS : ''}
          ${orderState.isCombineExplode ? ACTIVE_CLASS : ''}`}
        onClick={handleCombineClick}
      >
        <FiltersCombineSvg />
      </div>
    </Tooltip>
  )
}

export default ToggleCombine
