import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { Translation } from '../../../helpers/Translation'
import {
  ACTIVE_CLASS,
  HIDE_DROPDOWN_TIMEOUT,
  IMAGE_SORTINGS,
} from '../../../helpers/Constants'
import {
  getGalleryUrl,
} from '../../../helpers/Utils'

import Checkbox from '../../../components/Checkbox'
import Select from '../../../components/Select'

import { ReactComponent as FiltersSearchSvg } from '../../../svg/filters_search.svg'
import { ReactComponent as FilterSvg } from '../../../svg/filter.svg'
import { ReactComponent as EnterSvg } from '../../../svg/enter.svg'

import './index.scss'

const Filters = () => {
  const navigate = useNavigate()

  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const orderState = useStoreState((state) => ({
    images: state.order.images,
    filterWord: state.order.filterWord,
    filterBy: state.order.filterBy,
    imageType: state.order.imageType,
    orderId: state.order.orderId,
    imageSize: state.order.imageSize,
    imageSort: state.order.imageSort,
    isCombineExplode: state.order.isCombineExplode,
    gallery: state.order.gallery,
  }))

  const orderActions = useStoreActions((actions) => ({
    setFilterWord: actions.order.setFilterWord,
    setFilteredImages: actions.order.setFilteredImages,
    setImageSort: actions.order.setImageSort,
    setIsCombineExplode: actions.order.setIsCombineExplode,
    resetImagesFilters: actions.order.resetImagesFilters,
  }))

  const [isFocused, setIsFocused] = useState(false)
  const [sorting, setSorting] = useState(IMAGE_SORTINGS.id)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [filterChecks, setFilterChecks] = useState({
    name: false,
    folder: false,
    path: false,
    layer: false,
    channel: false,
  })

  const isMouseOverRef = useRef(false)

  const handleFilterWord = (e) => {
    orderActions.setFilterWord(e.target.value.trim())
  }

  useEffect(() => {
    if (orderState.filterWord === '') {
      orderActions.setFilteredImages(orderState.images)
    } else {
      const filteredImages = orderState.images.filter(
        // eslint-disable-next-line no-confusing-arrow
        (image) => orderState.filterBy === 'file'
          ? image.name.toLowerCase().includes(orderState.filterWord.toLowerCase())
          : image.folder.toLowerCase().includes(orderState.filterWord.toLowerCase()),
      )
      orderActions.setFilteredImages(filteredImages)
    }
  }, [
    orderState.filterWord,
    orderState.filterBy,
    orderState.images,
  ])

  const onHandleMouseLeave = () => {
    isMouseOverRef.current = false

    setTimeout(() => {
      if (isFocused && !isMouseOverRef.current) {
        setIsFocused(false)
      }
    }, HIDE_DROPDOWN_TIMEOUT)
  }

  const onHandleMouseEnter = () => {
    isMouseOverRef.current = true
  }

  const applyFilters = () => {
    orderActions.setImageSort(sorting)
    navigate(getGalleryUrl(
      userState.user.is_admin,
      orderState.imageType,
      orderState.orderId,
      orderState.imageSize,
      sorting,
    ))
    setIsFilterOpen(false)
  }

  return (
    <div
      className={`header--filters__search--wrap 
          ${(isFocused || orderState.filterWord) ? ACTIVE_CLASS : ''}`}
      onMouseLeave={onHandleMouseLeave}
      onMouseEnter={onHandleMouseEnter}
    >
      <input
        type="text"
        placeholder={Translation.image_search}
        className="header--filters__search"
        value={orderState.filterWord}
        onChange={handleFilterWord}
        onFocus={() => setIsFocused(true)}
        style={{
          borderBottomLeftRadius: isFocused ? 0 : '5px',
          borderBottomRightRadius: isFocused ? 0 : '5px',
        }}
      />
      <FiltersSearchSvg className="header--filters__search--icon" />
      <button
        type="button"
        aria-label="filter"
        className="header--filters__search--btn"
        onClick={() => setIsFilterOpen(!isFilterOpen)}
      >
        <FilterSvg className="header--filter--icon" />
      </button>

      {isFilterOpen && (
        <div className="header--filters__search--box">
          <div className="checkboxes">
            <div className="check-item">
              <Checkbox
                id="filterName"
                label={Translation.name}
                checked={filterChecks.name}
                onChange={(e) => setFilterChecks({ ...filterChecks, name: e.target.checked })}
              />
            </div>
            <div className="check-item">
              <Checkbox
                id="filterFolder"
                label={Translation.folder}
                checked={filterChecks.folder}
                onChange={(e) => setFilterChecks({ ...filterChecks, folder: e.target.checked })}
              />
            </div>
            <div className="check-item">
              <Checkbox
                id="filterPath"
                label={Translation.path}
                checked={filterChecks.path}
                onChange={(e) => setFilterChecks({ ...filterChecks, path: e.target.checked })}
              />
            </div>
            <div className="check-item">
              <Checkbox
                id="filterLayer"
                label={Translation.layer}
                checked={filterChecks.layer}
                onChange={(e) => setFilterChecks({ ...filterChecks, layer: e.target.checked })}
              />
            </div>
            <div className="check-item">
              <Checkbox
                id="filterChannel"
                label={Translation.channel}
                checked={filterChecks.channel}
                onChange={(e) => setFilterChecks({ ...filterChecks, channel: e.target.checked })}
              />
            </div>
          </div>

          <div className="row">
            <span>{Translation.filetype}</span>
            <Select
              options={[
                { value: '0', label: Translation.select_option },
                { value: 'jpg', label: 'JPG' },
                { value: 'png', label: 'PNG' },
                { value: 'svg', label: 'SVG' },
              ]}
              onChange={() => {
              }}
              value="0"
            />
          </div>

          <div className="row pixels">
            <div>
              <Select
                options={[
                  {
                    value: 'equals',
                    label: Translation.equals,
                  },
                  {
                    value: 'lower',
                    label: Translation.lower,
                  },
                  {
                    value: 'bigger',
                    label: Translation.bigger,
                  },
                ]}
                onChange={() => {
                }}
                value="0"
              />
            </div>
            <div>
              <input type="text" className="filter-search" placeholder="E.g. 3000" />
            </div>
            <div>
              <Select
                options={[
                  { value: 'px', label: Translation.px },
                ]}
                onChange={() => {
                }}
                value="px"
              />
            </div>
          </div>

          <div className="row">
            <span>{Translation.bg_colour}</span>
            <div className="bg-color-container">
              <div className="color-selector" />
              <input type="text" className="filter-search bg-color" placeholder="E.g. #451254" />
            </div>
          </div>

          <div className="row">
            <span>{Translation.colour_profile}</span>
            <Select
              options={[
                {
                  value: '0',
                  label: Translation.select_option,
                },
                {
                  value: 'srgb',
                  label: 'sRGB',
                },
                {
                  value: 'cmyk',
                  label: 'CMYK',
                },
                {
                  value: 'adobe_rgb_1998',
                  label: 'Adobe RGB (1998)',
                },
                {
                  value: 'ecirgb_v2',
                  label: 'eciRGB v2',
                },
              ]}
              onChange={() => {
              }}
              value="0"
            />
          </div>

          <div className="separator" />

          <div className="row">
            <span>{Translation.sorting}</span>
            <Select
              options={[
                {
                  value: IMAGE_SORTINGS.folderId,
                  label: `${Translation.folder} ▸ ${Translation.image_name_original}`,
                },
                {
                  value: IMAGE_SORTINGS.folderFilename,
                  label: `${Translation.folder} ▸ ${Translation.image_name_edited}`,
                },
                {
                  value: IMAGE_SORTINGS.id,
                  label: Translation.image_name_original,
                },
                {
                  value: IMAGE_SORTINGS.filename,
                  label: Translation.image_name_edited,
                },
                {
                  value: IMAGE_SORTINGS.formatFolderFilename,
                  label: `${Translation.format} ▸ ${Translation.folder} ▸ ${Translation.image_name_edited}`,
                },
                {
                  value: IMAGE_SORTINGS.product,
                  label: Translation.product_id,
                },
              ]}
              onChange={(e) => setSorting(e.target.value)}
              value={sorting}
            />
          </div>

          <button
            type="button"
            className="btn-apply-filter"
            onClick={applyFilters}
          >
            {Translation.apply}
            <EnterSvg />
          </button>
        </div>
      )}
    </div>
  )
}

export default Filters
