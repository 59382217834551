import React, { useState } from 'react'

import { useStoreState } from 'easy-peasy'
import { Translation } from '../../../../helpers/Translation'
import { ACTIVE_CLASS } from '../../../../helpers/Constants'
import {
  getGalleryUrl,
  getListUrl,
} from '../../../../helpers/Utils'

import Range from '../../../../components/Range'

import ImageSizeRange from '../../ImageSizeRange'

import { ReactComponent as PreviewTypeGallerySvg } from '../../../../svg/preview_type_gallery.svg'
import { ReactComponent as LayerRadioButtonIconUnselectedSvg } from '../../../../svg/layer_radio_button_unselected.svg'
import { ReactComponent as LayerRadioButtonIconSelectedSvg } from '../../../../svg/layer_radio_button_selected.svg'

import './index.scss'

const PreviewTypeOptions = () => {
  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const layoutState = useStoreState((state) => ({
    isListView: state.layout.isListView,
  }))

  const orderState = useStoreState((state) => ({
    imageType: state.order.imageType,
    orderId: state.order.orderId,
    imageSize: state.order.imageSize,
    imageSort: state.order.imageSort,
  }))

  const [gallerySize, setGallerySize] = useState('imageSize')
  const [gridSize, setGridSize] = useState(1)

  const togglePreviewType = (type) => {
    if (type === 'list') {
      window.location.href = getListUrl(
        userState.user.is_admin,
        orderState.imageType,
        orderState.orderId,
      )
      /* navigate(
        getListUrl(
          userState.user.is_admin,
          orderState.imageType,
          orderState.orderId,
        ),
      ) */
    } else {
      window.location.href = getGalleryUrl(
        userState.user.is_admin,
        orderState.imageType,
        orderState.orderId,
        orderState.imageSize,
        orderState.imageSort,
      )
      /* navigate(getGalleryUrl(
        userState.user.is_admin,
        orderState.imageType,
        orderState.orderId,
        orderState.imageSize,
        orderState.imageSort,
      )) */
    }
  }

  return (
    <div>
      <p className="container-title roobert-medium">
        {Translation.preview_type}
      </p>

      <div className="preview-type">
        <div className="left">
          <div className="left-top">
            <input
              type="radio"
              className="preview-type-radio"
              id="previewTypeGallery"
              name="previewType"
              value="gallery"
              checked={!layoutState.isListView}
              onChange={() => togglePreviewType('gallery')}
            />
            <label htmlFor="previewTypeGallery" className="radio-icon">
              {!layoutState.isListView ? (
                <LayerRadioButtonIconSelectedSvg />
              ) : (
                <LayerRadioButtonIconUnselectedSvg />
              )}
            </label>
            <label htmlFor="previewTypeGallery" className="preview-type--label">
              {Translation.gallery}
            </label>
          </div>
          <div className="left-bottom">
            <PreviewTypeGallerySvg />
          </div>
        </div>
        <div className="right">
          <div className="right-top">
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation()
                setGallerySize('imageSize')
              }}
              className={`btn-gallery-size roobert-medium ${gallerySize === 'imageSize' ? ACTIVE_CLASS : ''}`}
            >
              {Translation.use_image_size}
            </button>
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation()
                setGallerySize('grid')
              }}
              className={`btn-gallery-size roobert-medium ${gallerySize === 'grid' ? ACTIVE_CLASS : ''}`}
            >
              {Translation.use_grid}
            </button>
          </div>
          <div className="right-bottom">
            {gallerySize === 'imageSize' && (
              <div className="image-size">
                <ImageSizeRange />
              </div>
            )}
            {gallerySize === 'grid' && (
              <div className="grid-size">
                <p className="grid-size-desc">
                  {Translation.grid_size}
                  :
                  {' '}
                  <span>{gridSize}</span>
                </p>
                <Range
                  value={gridSize}
                  min={1}
                  max={12}
                  showLabels
                  onChange={(e) => {
                    e.preventDefault()
                    setGridSize(parseInt(e.target.value, 10))
                  }}
                />
                <p className="notice">
                  {Translation.notice_images_expand}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="preview-type list">
        <div className="left">
          <div className="left-top">
            <input
              type="radio"
              className="preview-type-radio"
              id="previewTypeList"
              name="previewType"
              value="list"
              checked={layoutState.isListView}
              onChange={() => togglePreviewType('list')}
            />
            <label htmlFor="previewTypeList" className="radio-icon">
              {layoutState.isListView ? (
                <LayerRadioButtonIconSelectedSvg />
              ) : (
                <LayerRadioButtonIconUnselectedSvg />
              )}
            </label>
            <label htmlFor="previewTypeList" className="preview-type--label">
              {Translation.list}
            </label>
          </div>
          <div className="left-bottom">
            <PreviewTypeGallerySvg />
          </div>
        </div>
        <div className="right">
          <p className="description">{Translation.in_list_view}</p>
        </div>
      </div>
    </div>
  )
}

export default PreviewTypeOptions
