import React, { useEffect, useRef, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { Translation } from '../../../helpers/Translation'
import { HIDE_DROPDOWN_TIMEOUT, DIALOG_NAMES } from '../../../helpers/Constants'

import { ReactComponent as OrderStatusSvg } from '../../../svg/order_status.svg'
import { ReactComponent as RedoSvg } from '../../../svg/redo_icon.svg'

import './index.scss'

const OrderStatusUpdate = () => {
  const orderState = useStoreState((state) => ({
    gallery: state.order.gallery,
  }))

  const layoutActions = useStoreActions((actions) => ({
    updateDialogModalOpened: actions.layout.updateDialogModalOpened,
    updateDialogModalState: actions.layout.updateDialogModalState,
  }))

  const isMouseOverRef = useRef(false)
  const isOpenedRef = useRef(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    isOpenedRef.current = isMenuOpen
  }, [
    isMenuOpen,
  ])

  const onHandleMenuToggle = (e) => {
    e.stopPropagation()

    setIsMenuOpen(!isMenuOpen)
  }

  const onHandleMouseLeave = () => {
    isMouseOverRef.current = false

    setTimeout(() => {
      if (isOpenedRef.current && !isMouseOverRef.current) {
        setIsMenuOpen(false)
      }
    }, HIDE_DROPDOWN_TIMEOUT)
  }

  const onHandleMouseEnter = () => {
    isMouseOverRef.current = true
  }

  const onStatusClick = (state) => {
    layoutActions.updateDialogModalOpened(true)
    layoutActions.updateDialogModalState(state)
  }

  return (
    <div
      className="order-status-wrapper"
      onClick={onHandleMenuToggle}
      onMouseLeave={onHandleMouseLeave}
      onMouseEnter={onHandleMouseEnter}
    >
      <button type="button" className="order-status-btn" aria-label="Order Status">
        <OrderStatusSvg />
      </button>

      <div
        className="status-list"
        style={{ display: isMenuOpen ? '' : 'none' }}
      >
        {orderState.gallery?.is_send_into_redo ? (
          <div className="status-item" onClick={() => onStatusClick(DIALOG_NAMES.orderStatusRedo)}>
            <RedoSvg className="top-1 right-2" />
            <span>{Translation.redo}</span>
          </div>
        ) : null}

        {orderState.gallery?.is_approval_required ? (
          <div className="status-item" onClick={() => onStatusClick(DIALOG_NAMES.orderStatusApprove)}>
            <div className="status-color orange" />
            <span>{Translation.approve}</span>
          </div>
        ) : null}

        {orderState.gallery?.is_redelivery ? (
          <div className="status-item" onClick={() => onStatusClick(DIALOG_NAMES.orderStatusReDeliverChanges)}>
            <div className="status-color red" />
            <span>{Translation.redeliver_changes}</span>
          </div>
        ) : null}

        {orderState.gallery?.is_partial_delivery ? (
          <div className="status-item" onClick={() => onStatusClick(DIALOG_NAMES.orderStatusPartialDelivery)}>
            <div className="status-color red" />
            <span>{Translation.partial_delivery}</span>
          </div>
        ) : null}

        {orderState.gallery?.is_client_qa_comment ? (
          <div className="status-item" onClick={() => onStatusClick(DIALOG_NAMES.orderStatusSendInputComments)}>
            <div className="status-color orange" />
            <span>{Translation.send_input_comments}</span>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default OrderStatusUpdate
