import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  IMAGE_ERROR_CLASS,
  IMAGE_ERROR_CODES,
  POINTER_CLASS,
} from '../../../../../helpers/Constants'
import { Translation } from '../../../../../helpers/Translation'
import { isAltOrCommandKey } from '../../../../../helpers/Utils'

const ImageBackgroundColor = ({
  isOriginalImage,
  originalImageDetails,
  image,
  removeErrorCode,
}) => {
  const [isPointerActive, setPointerActive] = useState(false)

  const handleMouseMove = (e) => {
    setPointerActive(isAltOrCommandKey(e))
  }

  const hasNoBgColor = isOriginalImage ? originalImageDetails?.no_bg_color : image.no_bg_color
  const bgColor = isOriginalImage ? originalImageDetails?.background_color : image.background_color
  const detectedBgText = bgColor ? `${Translation.detected_background}: ${bgColor}` : Translation.background_colour_not_detected

  return (
    <div className={hasNoBgColor ? 'info info-error' : 'info'}>
      {hasNoBgColor ? (
        <span
          className={`${IMAGE_ERROR_CLASS} ${isPointerActive ? POINTER_CLASS : ''}`}
          onMouseMove={handleMouseMove}
          onClick={(e) => removeErrorCode(e, IMAGE_ERROR_CODES.noBgColor)}
        >
          {detectedBgText}
        </span>
      ) : (
        detectedBgText
      )}
    </div>
  )
}

ImageBackgroundColor.propTypes = {
  isOriginalImage: PropTypes.bool.isRequired,
  originalImageDetails: PropTypes.shape({
    no_bg_color: PropTypes.bool,
    background_color: PropTypes.string,
  }),
  image: PropTypes.shape({
    no_bg_color: PropTypes.bool,
    background_color: PropTypes.string,
  }).isRequired,
  removeErrorCode: PropTypes.func.isRequired,
}

ImageBackgroundColor.defaultProps = {
  originalImageDetails: {
    no_bg_color: false,
    background_color: '',
  },
}

export default ImageBackgroundColor
