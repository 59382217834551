import axios from 'axios'
import { Cookie } from './Cookie'

import { Translation } from './Translation'
import {
  MY_DOOPIC_URLS,
  API_ENDPOINTS,
} from './Urls'

// eslint-disable-next-line import/no-mutable-exports
export let axiosInstance = null

export const enhanceAxiosInstance = (store) => {
  // axios instance must exist only once
  if (axiosInstance !== null) return axiosInstance

  axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  })

  // const idRe = /\/{id}$/

  /* const excludedUrls = [
    API_ENDPOINTS.me,
    API_ENDPOINTS_GALLERY.links,

    API_ENDPOINTS_GALLERY.ping.replace(GALLERY_PREFIX_RE, CLIENT_GALLERY_PREFIX),
    API_ENDPOINTS_GALLERY.ping.replace(GALLERY_PREFIX_RE, ADMIN_GALLERY_PREFIX),
    API_ENDPOINTS_GALLERY.ping.replace(GALLERY_PREFIX_RE, PUBLIC_GALLERY_PREFIX),

    API_ENDPOINTS_GALLERY.gallery.replace(idRe, '').replace(GALLERY_PREFIX_RE, CLIENT_GALLERY_PREFIX),
    API_ENDPOINTS_GALLERY.gallery.replace(idRe, '').replace(GALLERY_PREFIX_RE, ADMIN_GALLERY_PREFIX),
    API_ENDPOINTS_GALLERY.gallery.replace(idRe, '').replace(GALLERY_PREFIX_RE, PUBLIC_GALLERY_PREFIX),

    API_ENDPOINTS_GALLERY.images.replace(idRe, '').replace(GALLERY_PREFIX_RE, CLIENT_GALLERY_PREFIX),
    API_ENDPOINTS_GALLERY.images.replace(idRe, '').replace(GALLERY_PREFIX_RE, ADMIN_GALLERY_PREFIX),
    API_ENDPOINTS_GALLERY.images.replace(idRe, '').replace(GALLERY_PREFIX_RE, PUBLIC_GALLERY_PREFIX),
  ] */

  // const isToShowLoader = (url) => !excludedUrls.includes(url.replace(/\/\d+$/, ''))
  const isToShowLoader = () => false // loader is shown from <Loader> component

  axiosInstance.interceptors.request.use(
    (config) => {
      if ((config.url.includes('/template') && config.method === 'patch')) {
        // eslint-disable-next-line no-param-reassign
        config.withCredentials = true
        return config
      }

      if (isToShowLoader(config.url)) {
        // this will trigger showing of page blocking loader
        store.getActions().global.increaseCounter()
      }

      if (config.url !== API_ENDPOINTS.login) {
        // eslint-disable-next-line no-param-reassign
        config.withCredentials = true
      }

      return config
    },
    (error) => Promise.reject(error),
  )

  axiosInstance.interceptors.response.use(
    (response) => {
      if (isToShowLoader(response.config.url)) store.getActions().global.decreaseCounter()
      return response
    },
    (error) => {
      if (store.getState().global.requestCount > 0) {
        store.getActions().global.decreaseCounter()
      }

      if (error.response && error.response.status === 401) {
        Cookie.deleteCookie('PHPSESSID')
        window.location.href = `${MY_DOOPIC_URLS.login}`
      }

      // handle 500 errors and show the snackbar with error type
      if (error.response && error.response.status === 500) {
        store.getActions().global.updateSnackbarState({
          isOpen: true,
          message: Translation.nonce,
          type: 'error',
        })
      }
      return Promise.reject(error)
    },
  )

  return store
}
