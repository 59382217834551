import React, { useEffect, useRef, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { Translation } from '../../../helpers/Translation'
import { dragElement } from '../../../helpers/Utils'
import { PX_TO_REM } from '../../../helpers/Constants'

import Typography from '../../../components/Typography'
import Checkbox from '../../../components/Checkbox'
import Tooltip from '../../../components/Tooltip'

import { ReactComponent as ChevronDownSvg } from '../../../svg/info_bar_chevron_down.svg'
import { ReactComponent as CrossSvg } from '../../../svg/action_cross.svg'
import { ReactComponent as StatusClose } from '../../../svg/status_close.svg'
import { ReactComponent as StatusEye } from '../../../svg/status_eye.svg'
import { ReactComponent as StatusScissors } from '../../../svg/status_scissors.svg'
import { ReactComponent as StatusCheck } from '../../../svg/status_check.svg'
import { ReactComponent as StatusArrows } from '../../../svg/status_arrows.svg'
import { ReactComponent as StatusComment } from '../../../svg/status_comment.svg'
import { ReactComponent as StatusClientComment } from '../../../svg/status_client_comment.svg'
import { ReactComponent as StatusCreditCard } from '../../../svg/status_credit_card.svg'
import { ReactComponent as StatusRecycle } from '../../../svg/status_recycle.svg'
import { ReactComponent as StatusRecycleOval } from '../../../svg/status_recycle_oval.svg'
import { ReactComponent as StatusSandWatch } from '../../../svg/status_sand_watch.svg'
import { ReactComponent as StatusSettings } from '../../../svg/status_settings.svg'

import './index.scss'

const orderStatusIcons = {
  cogs: {
    icon: <StatusSettings className="top-3" />,
  },
  eye: {
    icon: <StatusEye className="top-3" />,
  },
  comments: {
    icon: <StatusComment className="top-3" />,
  },
  client_comments: {
    icon: <StatusClientComment className="top-3" />,
  },
  creditcard: {
    icon: <StatusCreditCard className="top-3" />,
  },
  refresh: {
    icon: <StatusRecycle className="top-3" />,
  },
  retweet: {
    icon: <StatusRecycleOval className="top-3" />,
  },
  exchange: {
    icon: <StatusArrows className="top-3" />,
  },
  check: {
    icon: <StatusCheck className="top-5" />,
  },
  hourglass: {
    icon: <StatusSandWatch className="top-3" />,
  },
  times: {
    icon: <StatusClose className="top-3" />,
  },
  cut: {
    icon: <StatusScissors className="top-3" />,
  },
}

const InfoBar = () => {
  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const layoutState = useStoreState((state) => ({
    isInfosOpened: state.layout.isInfosOpened,
  }))

  const layoutActions = useStoreActions((actions) => ({
    updateInfosOpened: actions.layout.updateInfosOpened,
  }))

  const orderState = useStoreState((state) => ({
    gallery: state.order.gallery,
  }))

  const [isInitialPos, setIsInitialPos] = useState(true)
  const [isGeneralInfoCollapsed, setIsGeneralInfoCollapsed] = useState(false)
  const [isChecklistCollapsed, setIsChecklistCollapsed] = useState(false)

  const infoBarRef = useRef(null)
  const dragEventRef = useRef(null)

  const handleCloseClick = () => {
    layoutActions.updateInfosOpened(false)
  }

  const handleMouseUp = () => {
    if ((infoBarRef.current.style.top === '0px' && infoBarRef.current.style.left === '0px')
      || (infoBarRef.current.style.top === '' && infoBarRef.current.style.left === '')) {
      setIsInitialPos(true)
    } else {
      setIsInitialPos(false)
    }
  }

  useEffect(() => {
    if (layoutState.isInfosOpened) {
      dragEventRef.current = dragElement(infoBarRef.current)
      infoBarRef.current?.addEventListener('mousedown', dragEventRef.current)
      infoBarRef.current?.addEventListener('mouseup', handleMouseUp)
    }

    // cleanup: "remove event listeners" and "clear intervals" to avoid memory leaks
    return () => {
      infoBarRef.current?.removeEventListener('mousedown', dragEventRef.current)
      infoBarRef.current?.removeEventListener('mouseup', handleMouseUp)
    }
  }, [
    layoutState.isInfosOpened,
  ])

  const handleCollapseClick = (section) => {
    if (section === 'general') {
      setIsGeneralInfoCollapsed(!isGeneralInfoCollapsed)
    } else {
      setIsChecklistCollapsed(!isChecklistCollapsed)
    }
  }

  return (
    <div
      className="info-bar scrollbar-overflow scrollbar-overflow__small"
      ref={infoBarRef}
      style={{ bottom: isInitialPos ? '0' : 'auto' }}
    >
      <button
        className="info-bar_close"
        type="button"
        label={Translation.close}
        onClick={handleCloseClick}
      >
        <CrossSvg />
      </button>

      <div className="order-status">
        <div className={`${orderState.gallery?.order_status_is_warning ? 'order-status--warning' : ''}`}>
          {orderStatusIcons[orderState.gallery?.order_status_icon]?.icon}
        </div>

        <Typography
          variant="body1"
          font="semibold"
          label={orderState.gallery?.order_status_name}
          lineHeight={PX_TO_REM['24']}
        />
      </div>

      <div className="order-name">
        <Typography
          variant="body1"
          font="semibold"
          label={orderState.gallery?.order?.name}
          lineHeight={PX_TO_REM['24']}
        />
        {orderState.gallery?.order?.template_name && (
          <div className="order-template">
            <Typography
              variant="body1"
              font="semibold"
              label={orderState.gallery?.order?.template_name}
              lineHeight={PX_TO_REM['24']}
            />
          </div>
        )}
      </div>

      <div className="section">
        {/* <button
          className="collapse-btn"
          type="button"
          label="collapse"
          onClick={() => handleCollapseClick('general')}
        >
          <ChevronDownSvg />
        </button> */}

        {/* <div className="section-header">
          <Typography
            variant="body1"
            font="semibold"
            label={Translation.general_order_info}
            lineHeight={PX_TO_REM['24']}
          />
        </div> */}

        <div className="separator" />

        {!isGeneralInfoCollapsed && (
          orderState.gallery?.order_actions.map((orderDetailsItem, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>
              {orderState.gallery?.order_actions.length > 1 && (
                <div className="section-header">
                  <Typography
                    variant="body1"
                    font="semibold"
                    label={`${orderState.gallery?.order_actions.length > 1 ? `${index + 1}. ${Translation.format}` : ''}`}
                    lineHeight={PX_TO_REM['24']}
                  />
                </div>
              )}

              {orderDetailsItem?.children?.map((item) => (
                <div key={`${item.label}-${Math.random()}`}>
                  <Typography
                    variant="body1"
                    font="semibold"
                    label={item.label}
                    lineHeight={PX_TO_REM['24']}
                  />
                  <ul>
                    {item?.children && (
                      Object.values(item.children).map((child) => (
                        <li key={`${child.label}-${Math.random()}`}>
                          <span className="order-action-value">
                            {child.label}
                          </span>

                          {child.type === 'color_box' && (
                            <Tooltip text={child.value}>
                              <div
                                className="color-box"
                                style={{ backgroundColor: child.value }}
                              />
                            </Tooltip>
                          )}
                        </li>
                      ))
                    )}
                  </ul>
                </div>
              ))}

              {userState.user.is_admin && orderState.gallery?.guide_link && (
                <>
                  <div>
                    <Typography
                      variant="body1"
                      font="semibold"
                      label={Translation.guide_link}
                      lineHeight={PX_TO_REM['24']}
                    />
                  </div>
                  <ul>
                    <li>
                      <a
                        href={orderState.gallery?.guide_link}
                        className="info--guide-link overflow-ellipsis"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {orderState.gallery?.guide_link}
                      </a>
                    </li>
                  </ul>
                </>
              )}

              {userState.user.is_admin && orderState.gallery?.order?.remark_internal && (
                <>
                  <div>
                    <Typography
                      variant="body1"
                      font="semibold"
                      label={Translation.internal_remarks}
                      lineHeight={PX_TO_REM['24']}
                    />
                  </div>
                  <ul>
                    <li>
                      {orderState.gallery?.order?.remark_internal}
                    </li>
                  </ul>
                </>
              )}

              {userState.user.is_admin && orderState.gallery?.order?.remark_sp && (
                <>
                  <div>
                    <Typography
                      variant="body1"
                      font="semibold"
                      label="Remark SP"
                      lineHeight={PX_TO_REM['24']}
                    />
                  </div>
                  <ul>
                    <li>
                      {orderState.gallery?.order?.remark_sp}
                    </li>
                  </ul>
                </>
              )}

              <div className="separator" />
            </div>
          ))
        )}
      </div>

      {/* TODO: will be implemented in gallery V2 */}
      <div className="section" style={{ display: 'none' }}>
        <button
          className="collapse-btn"
          type="button"
          label="collapse"
          onClick={() => handleCollapseClick('checklist')}
        >
          <ChevronDownSvg />
        </button>

        <div className="section-header">
          <Typography
            variant="body1"
            font="semibold"
            label={Translation.checklist}
            lineHeight={PX_TO_REM['24']}
          />
        </div>

        {!isChecklistCollapsed && (
          <div>
            <ul>
              <li>
                <div className="check-item">
                  <Typography
                    label="Scars"
                    fontSize={PX_TO_REM['11']}
                    lineHeight={PX_TO_REM['24']}
                  />
                  <Checkbox size="l" />
                </div>
              </li>
              <li>
                <div className="check-item">
                  <Typography
                    label="Pimples"
                    fontSize={PX_TO_REM['11']}
                    lineHeight={PX_TO_REM['24']}
                  />
                  <Checkbox size="l" />
                </div>
              </li>
              <li>
                <div className="check-item">
                  <Typography
                    label="Moles"
                    fontSize={PX_TO_REM['11']}
                    lineHeight={PX_TO_REM['24']}
                  />
                  <Checkbox size="l" />
                </div>
              </li>
              <li>
                <div className="check-item">
                  <Typography
                    label="Tatoos"
                    fontSize={PX_TO_REM['11']}
                    lineHeight={PX_TO_REM['24']}
                  />
                  <Checkbox size="l" />
                </div>
              </li>
              <li>
                <div className="check-item">
                  <Typography
                    label="Beauty"
                    fontSize={PX_TO_REM['11']}
                    lineHeight={PX_TO_REM['24']}
                  />
                  <Checkbox size="l" />
                </div>
              </li>
              <li>
                <div className="check-item">
                  <Typography
                    label="Other"
                    fontSize={PX_TO_REM['11']}
                    lineHeight={PX_TO_REM['24']}
                  />
                  <Checkbox size="l" />
                </div>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

export default InfoBar
