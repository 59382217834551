import React from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { DISABLED_CLASS, PX_TO_REM } from '../../../helpers/Constants'
import { Translation } from '../../../helpers/Translation'

import Tooltip from '../../../components/Tooltip'

import { ReactComponent as RedoIconSvg } from '../../../svg/redo_icon.svg'

import './index.scss'

const Redo = () => {
  const layoutActions = useStoreActions((actions) => ({
    updateRedoModalOpened: actions.layout.updateRedoModalOpened,
  }))

  const orderState = useStoreState((state) => ({
    gallery: state.order.gallery,
  }))

  const onRedoClick = () => {
    if (!orderState.gallery?.is_send_into_redo) return

    layoutActions.updateRedoModalOpened(true)
  }

  return (
    <Tooltip
      text={Translation.send_into_redo}
      position="bottom"
      offset={PX_TO_REM['60']}
    >
      <div
        className={
          `header--wraps header--send-redo__wrap 
          ${orderState.gallery?.is_send_into_redo ? '' : DISABLED_CLASS}`
        }
        onClick={onRedoClick}
      >
        <RedoIconSvg />
      </div>
    </Tooltip>
  )
}

export default Redo
