import React, { useEffect, useState } from 'react'
import { useStoreState } from 'easy-peasy'

import { Translation } from '../../../helpers/Translation'
import { PX_TO_REM } from '../../../helpers/Constants'
import { MY_DOOPIC_URLS } from '../../../helpers/Urls'

import Tooltip from '../../../components/Tooltip'

import { ReactComponent as FiltersChatSvg } from '../../../svg/filters_chat.svg'

import './index.scss'

const Chat = () => {
  const orderState = useStoreState((state) => ({
    generalUnreadMessageCount: state.order.generalUnreadMessageCount,
  }))

  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    let blinkInterval

    if (orderState.generalUnreadMessageCount) {
      blinkInterval = setInterval(() => {
        setIsVisible((prevVisible) => !prevVisible)
      }, 2000) // timeout must correspond with CSS animation
    }

    // cleanup: "remove event listeners" and "clear intervals" to avoid memory leaks
    return () => {
      if (blinkInterval) {
        clearInterval(blinkInterval)
      }
    }
  }, [
    orderState.generalUnreadMessageCount,
  ])

  const redirectToChat = () => {
    window.open(
      MY_DOOPIC_URLS.chat,
      '_blank',
    )
  }

  return (
    <Tooltip
      text={Translation.chat}
      position="bottom"
      offset={PX_TO_REM['51']}
    >
      <div
        className="header--filters__chat"
        onClick={redirectToChat}
      >
        {orderState.generalUnreadMessageCount ? (
          <div
            className="header--filters__chat--counter blinking"
            style={{ opacity: isVisible ? 1 : 0 }}
          >
            {orderState.generalUnreadMessageCount}
          </div>
        ) : null}
        <FiltersChatSvg />
      </div>
    </Tooltip>
  )
}

export default Chat
