export const MY_DOOPIC_URLS = {
  login: `${process.env.REACT_APP_MY_DOOPIC_URL}`,
  dashboard: `${process.env.REACT_APP_MY_DOOPIC_URL}/dashboard`,
  support: `${process.env.REACT_APP_MY_DOOPIC_URL}/support`,
  chat: `${process.env.REACT_APP_MY_DOOPIC_URL}/admin/iframe/chat`,
}

export const API_ENDPOINTS = {
  login: '/login/session',
  me: '/user/account/me',
  jsError: '/js/error',
}

export const GALLERY_PREFIX = '{gallery_prefix}'
export const GALLERY_PREFIX_RE = new RegExp(`^${GALLERY_PREFIX}`)

export const API_ENDPOINTS_GALLERY = {
  links: '/gallery/links',
  image: '/gallery/image',
  layer: '/gallery/image/layer',
  path: '/gallery/image/path',
  gallery: `${GALLERY_PREFIX}/{id}`,
  ping: `${GALLERY_PREFIX}/ping`,
  images: `${GALLERY_PREFIX}/images/{id}`,
  confirm: `${GALLERY_PREFIX}/image/confirm`,
  download: `${GALLERY_PREFIX}/image/download`,
  approve: `${GALLERY_PREFIX}/image/approve`,
  delete: `${GALLERY_PREFIX}/image/delete`,
  send: `${GALLERY_PREFIX}/image/send`,
  commentDelete: `${GALLERY_PREFIX}/image/comment/delete`,
  commentNew: `${GALLERY_PREFIX}/image/comment/new`,
  commentApprove: `${GALLERY_PREFIX}/image/comment/approve`,
  errors: `${GALLERY_PREFIX}/image/errors`,
  reference: `${GALLERY_PREFIX}/image/reference`,
  prices: `${GALLERY_PREFIX}/image/prices`,
  test: `${GALLERY_PREFIX}/image/test`,
  restore: `${GALLERY_PREFIX}/image/restore`,
  clear: `${GALLERY_PREFIX}/image/upload/clear`,
  explode: `${GALLERY_PREFIX}/order/explode`,
  redo: `${GALLERY_PREFIX}/order/status/redo`,
  partialDelivery: `${GALLERY_PREFIX}/order/status/partial_delivery`,
  redelivery: `${GALLERY_PREFIX}/order/status/redelivery`,
  approvalRequired: `${GALLERY_PREFIX}/order/status/approval_required`,
  clientQaInput: `${GALLERY_PREFIX}/order/status/client_qa_input`,
}
