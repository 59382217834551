import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useStoreActions, useStoreState } from 'easy-peasy'

import {
  ALL_LAYERS_KEY,
  CLIENT_DISPLAY_TYPE,
  COMMENT_STATUSES,
  COMMENT_TYPES,
  DEFAULT_OVERLAY_IMAGE_SIZES,
  IMAGE_SIZES,
  INTERVAL_1_SECOND,
} from '../../../../helpers/Constants'
import { Translation } from '../../../../helpers/Translation'
import {
  appendShapes,
  getEndpointUrl,
  getStrokeWidth,
  localizeDate,
  prepareComment,
  scrollToBottom,
} from '../../../../helpers/Utils'
import { API_ENDPOINTS_GALLERY } from '../../../../helpers/Urls'

import Checkbox from '../../../../components/Checkbox'
import Tooltip from '../../../../components/Tooltip'

import { ReactComponent as CommentedDrawingFreehandSvg } from '../../../../svg/commented_drawing_freehand.svg'
import { ReactComponent as CommentedTextSvg } from '../../../../svg/commented_text.svg'
import { ReactComponent as PreviewDownloadSvg } from '../../../../svg/preview_download.svg'
import { ReactComponent as VersionVisibleSvg } from '../../../../svg/version_visible.svg'
import { ReactComponent as VersionDeliveredSvg } from '../../../../svg/version_delivered.svg'
import { ReactComponent as CommentRejectedSvg } from '../../../../svg/comment_rejected.svg'

import './index.scss'

const HistoryOverlay = ({
  type,
  historyWrapRef,
  overlayImgDrawingRef,
  overlayContainerRef,
}) => {
  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const orderState = useStoreState((state) => ({
    images: state.order.images,
    imageShapes: state.order.imageShapes,
    imageDetails: state.order.imageDetails,
    overlayImgIndex: state.order.overlayImgIndex,
    orderId: state.order.orderId,
    imageType: state.order.imageType,
  }))

  const orderActions = useStoreActions((actions) => ({
    imageAction: actions.order.imageAction,
    getImageDetails: actions.order.getImageDetails,
    getVersion: actions.order.getVersion,
    setDownloadUrls: actions.order.setDownloadUrls,
    setImageDetails: actions.order.setImageDetails,
    setSelectedLayers: actions.order.setSelectedLayers,
  }))

  const [historyItems, setHistoryItems] = useState(null)
  const [versionChecks, setVersionChecks] = useState([])
  const strokeWidth = useRef(null)
  const imageSizes = useRef({ width: 0, height: 0 })

  const isHistory = (historyType) => historyType === 'history'

  const updateCheckedFlag = (items, version) => items.map((item) => (item.version
    ? {
      ...item,
      checked: item.version === version,
    } : item))

  const fetchImageVersion = async (id) => {
    const imageId = orderState.images[orderState.overlayImgIndex]?.id
    if (!imageId) return
    if (!orderState.imageDetails[imageId]?.image_versions) return

    const imageVersions = orderState.imageDetails[imageId]?.image_versions.find((item) => item.id === id)
    const image = orderState.images[orderState.overlayImgIndex]
    const imageResource = image.image_resource[image.id]

    const { version } = imageVersions
    const url = imageVersions?.url

    if (!url) { // image version is not loaded yet
      await orderActions.getVersion({
        image_ids: [image.id],
        s3_paths: [imageResource.s3_path],
        original_s3_paths: [imageResource.original_s3_path],
        formats: [imageResource.format],
        image_sizes: IMAGE_SIZES.extraLarge,
        thumbnail_types: [imageResource.thumbnail_type],
        versions: [version],
      })
    } else {
      const {
        image_history: imageHistoryPrev = orderState.imageDetails[imageId]?.image_history,
        image_versions: imageVersionsPrev = orderState.imageDetails[imageId]?.image_versions,
        ...otherImageDetails
      } = orderState.imageDetails[imageId]

      const updatedImageDetails = {}
      updatedImageDetails[imageId] = {
        ...otherImageDetails,
        url, // update url in fullscreen view
        image_history: imageHistoryPrev ? updateCheckedFlag(imageHistoryPrev, version) : null,
        image_versions: imageVersionsPrev ? updateCheckedFlag(imageVersionsPrev, version) : null,
      }
      orderActions.setImageDetails(updatedImageDetails)
    }
  }

  useEffect(() => {
    if (!orderState.imageDetails) return
    const imageId = orderState.images[orderState.overlayImgIndex]?.id
    if (!imageId) return

    if (isHistory(type)) {
      const imageHistory = orderState.imageDetails[imageId]?.image_history
      if (!imageHistory) {
        orderActions.getImageDetails({
          image_ids: [imageId],
          order_ids: [orderState.orderId],
          image_type: orderState.imageType,
          image_size: IMAGE_SIZES.extraLarge,
          is_with_comments: isHistory(type),
        })
      } else {
        setHistoryItems(imageHistory)
        setVersionChecks(imageHistory)
        scrollToBottom(historyWrapRef.current)
      }
    } else {
      const imageVersions = orderState.imageDetails[imageId]?.image_versions
      setHistoryItems(imageVersions)
      setVersionChecks(imageVersions)
      scrollToBottom(historyWrapRef.current)
    }
  }, [
    type,
    orderState.overlayImgIndex,
    orderState.imageDetails,
  ])

  const onChangeImageVersion = (id, checked) => {
    setVersionChecks((prevChecks) => prevChecks.map((version) => ({
      ...version,
      checked: version.id === id ? checked : false,
    })))

    const imageId = orderState.images[orderState.overlayImgIndex]?.id
    if (imageId) {
      orderActions.setSelectedLayers({
        imageId,
        layerId: `${ALL_LAYERS_KEY}-${imageId}`,
      })
    }

    fetchImageVersion(id).then(() => { })
  }

  const onClickImageVersionDownload = async (version) => {
    const image = orderState.images[orderState.overlayImgIndex]
    const imageResource = image.image_resource[image.id]

    const res = await orderActions.imageAction({
      url: getEndpointUrl(userState.user.is_admin, API_ENDPOINTS_GALLERY.download),
      body: {
        image_ids: [image.id],
        original_s3_paths: [imageResource.original_s3_path],
        thumbnail_types: [imageResource.thumbnail_type],
        versions: [version],
      },
    })
    if (res.success) {
      Object.keys(res.urls).forEach(() => {
        orderActions.setDownloadUrls(res.urls)
        setTimeout(() => {
          orderActions.setDownloadUrls({})
        }, INTERVAL_1_SECOND)
      })
    }
  }

  const onMouseEnter = (historyItem) => {
    const shapes = JSON.parse(historyItem.shapes)

    if (shapes && shapes.length) {
      // eslint-disable-next-line no-param-reassign
      overlayImgDrawingRef.current.innerHTML = ''

      imageSizes.current = {
        width: orderState.images[orderState.overlayImgIndex].width,
        height: orderState.images[orderState.overlayImgIndex].height,
      }

      strokeWidth.current = getStrokeWidth(
        imageSizes.current.width,
        overlayContainerRef.current?.offsetWidth || DEFAULT_OVERLAY_IMAGE_SIZES.width,
        true,
      )

      appendShapes(shapes, strokeWidth.current, overlayImgDrawingRef.current)
    }
  }

  const onMouseLeave = () => {
    imageSizes.current = {
      width: orderState.images[orderState.overlayImgIndex].width,
      height: orderState.images[orderState.overlayImgIndex].height,
    }

    strokeWidth.current = getStrokeWidth(
      imageSizes.current.width,
      overlayContainerRef.current?.offsetWidth || DEFAULT_OVERLAY_IMAGE_SIZES.width,
      true,
    )

    appendShapes(orderState.imageShapes, strokeWidth.current, overlayImgDrawingRef.current)
    if (orderState.shapesJson) {
      appendShapes(orderState.shapesJson, strokeWidth.current, overlayImgDrawingRef.current)
    }
  }

  return (
    historyItems && historyItems.map(
      (historyItem, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className={`overlay--comment__wrap 
            ${(historyItem.status === COMMENT_STATUSES.deleted || historyItem.status === COMMENT_STATUSES.rejected)
            ? 'overlay--comment__wrap--deleted' : ''}`}
          onMouseEnter={historyItem.comment ? () => onMouseEnter(historyItem) : null}
          onMouseLeave={historyItem.comment ? onMouseLeave : null}
          data-status={historyItem.status}
        >
          {historyItem.comment ? (
            <>
              <div className="overlay--comment__icon overlay--comment__type">
                {historyItem.shapes
                  ? <CommentedDrawingFreehandSvg stroke={historyItem.colour} />
                  : <CommentedTextSvg className="right-2" />}
              </div>

              {historyItem.type === COMMENT_TYPES.qaClient && (
                <div className={`overlay--comment__icon overlay--comment__icon--client 
                  ${parseInt(historyItem.status, 10) === COMMENT_STATUSES.active
                  ? 'overlay--comment__icon--client__approved' : ''}`}
                >
                  {Translation.client_short}
                </div>
              )}

              {historyItem.type === COMMENT_TYPES.clientQa && (
                <div className="overlay--comment__icon overlay--comment__icon--rejected">
                  <CommentRejectedSvg />
                </div>
              )}

              <div className="overlay--comment">
                {prepareComment(historyItem.comment)}
                {' '}
                <span className="overlay--comment__note">
                  {historyItem.nickname && (
                    <>
                      <Tooltip
                        text={`${historyItem.firstname} ${historyItem.lastname}`}
                        position={`${index === 0 ? 'bottom' : 'top'}`}
                      >
                        {historyItem.nickname}
                      </Tooltip>
                      {' '}
                      /
                      {' '}
                    </>
                  )}
                  <Tooltip
                    text={localizeDate(historyItem.timestamp)}
                    position={`${index === 0 ? 'bottom' : 'top'}`}
                  >
                    {localizeDate(historyItem.timestamp)}
                  </Tooltip>
                </span>
              </div>
            </>
          ) : (
            <>
              <Checkbox
                id={`version-${historyItem.id}`}
                label={historyItem.name}
                checked={versionChecks.some((version) => version.id === historyItem.id && version.checked)}
                onChange={(e) => onChangeImageVersion(historyItem.id, e.target.checked)}
              />
              {userState.user.is_admin && historyItem.display_type >= CLIENT_DISPLAY_TYPE && (
                <Tooltip
                  text={Translation.version_visible_client}
                  position={`${index === 0 ? 'bottom' : 'top'}`}
                >
                  <VersionVisibleSvg className="overlay--comment__version" />
                </Tooltip>
              )}
              {userState.user.is_admin && historyItem.timestamp_completed && (
                <Tooltip
                  text={Translation.version_delivered}
                  position={`${index === 0 ? 'bottom' : 'top'}`}
                >
                  <VersionDeliveredSvg className="overlay--comment__version" />
                </Tooltip>
              )}
              {/* <div className="overlay--comment">
                <span className="overlay--comment__note">
                  <Tooltip
                    text={localizeDate(historyItem.timestamp)}
                    position={`${index === 0 ? 'bottom' : 'top'}`}
                  >
                    {localizeDate(historyItem.timestamp)}
                  </Tooltip>
                </span>
              </div> */}
              <PreviewDownloadSvg
                className="overlay--comment__download"
                onClick={() => onClickImageVersionDownload(historyItem.version_download)}
              />
            </>
          )}
        </div>
      ),
    )
  )
}

HistoryOverlay.propTypes = {
  type: PropTypes.string,
  historyWrapRef: PropTypes.instanceOf(Object),
  overlayImgDrawingRef: PropTypes.instanceOf(Object),
  overlayContainerRef: PropTypes.instanceOf(Object),
}

HistoryOverlay.defaultProps = {
  type: '',
  historyWrapRef: {},
  overlayImgDrawingRef: {},
  overlayContainerRef: {},
}

export default HistoryOverlay
