import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { IMAGE_TYPES } from '../../../helpers/Constants'
import { Translation } from '../../../helpers/Translation'
import {
  getGalleryUrl,
  getListUrl,
} from '../../../helpers/Utils'

import './index.scss'

const ImageTypes = () => {
  const navigate = useNavigate()

  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const layoutState = useStoreState((state) => ({
    isListView: state.layout.isListView,
  }))

  const orderState = useStoreState((state) => ({
    orderId: state.order.orderId,
    imageType: state.order.imageType,
    imageSize: state.order.imageSize,
    imageSort: state.order.imageSort,
    gallery: state.order.gallery,
  }))

  const orderActions = useStoreActions((actions) => ({
    setImageType: actions.order.setImageType,
    setImages: actions.order.setImages,
    removeImages: actions.order.removeImages,
    setFilterWord: actions.order.setFilterWord,
    setFilteredImages: actions.order.setFilteredImages,
    resetImagesFilters: actions.order.resetImagesFilters,
  }))

  const handleImageTypeClick = (e) => {
    const { imageTypeUpdate } = e.currentTarget.dataset

    if (imageTypeUpdate === orderState.imageType) return

    orderActions.resetImagesFilters()
    orderActions.setImageType(imageTypeUpdate)

    if (layoutState.isListView) {
      navigate(
        getListUrl(
          userState.user.is_admin,
          imageTypeUpdate,
          orderState.orderId,
        ),
      )
    } else {
      navigate(getGalleryUrl(
        userState.user.is_admin,
        imageTypeUpdate,
        orderState.orderId,
        orderState.imageSize,
        orderState.imageSort,
      ))
    }
  }

  return (
    <div className="header--wraps header--image-types__wrap">
      <div
        className={`header--image-types__wrap--type 
            ${IMAGE_TYPES.input === orderState.imageType ? 'header--image-types__wrap--type__active' : ''}`}
        data-image-type-update={IMAGE_TYPES.input}
        onClick={handleImageTypeClick}
      >
        <div className="header--image-types__wrap--type__header">
          {Translation.input_short.toUpperCase()}
        </div>
        <div className="header--image-types__wrap--type__value">
          {orderState.gallery ? orderState.gallery.input_count : 0}
        </div>
      </div>

      <div
        className={`header--image-types__wrap--type
            ${IMAGE_TYPES.output === orderState.imageType ? 'header--image-types__wrap--type__active' : ''}`}
        data-image-type-update={IMAGE_TYPES.output}
        onClick={handleImageTypeClick}
      >
        <div className="header--image-types__wrap--type__header">
          {Translation.output_short.toUpperCase()}
        </div>
        <div className="header--image-types__wrap--type__value">
          {orderState.gallery ? orderState.gallery.output_count : 0}
        </div>
      </div>

      <div
        className={`header--image-types__wrap--type
            ${IMAGE_TYPES.compare === orderState.imageType ? 'header--image-types__wrap--type__active' : ''}`}
        data-image-type-update={IMAGE_TYPES.compare}
        onClick={handleImageTypeClick}
        style={{ display: 'none' }}
      >
        <div className="header--image-types__wrap--type__header">
          {Translation.compare.toUpperCase()}
        </div>
        <div className="header--image-types__wrap--type__value">
          {orderState.gallery ? orderState.gallery.compare_count : 0}
        </div>
      </div>
    </div>
  )
}

export default ImageTypes
