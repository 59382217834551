import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useStoreState } from 'easy-peasy'

import { Translation } from '../../../../helpers/Translation'

import CommentOverlay from '../CommentOverlay'
import Comment from '../Comment'

import { ReactComponent as ExpandSvg } from '../../../../svg/expand.svg'

import './index.scss'

const CollapsibleCommentOverlay = ({
  image,
  refreshGallery,
  resetInterval,
  resizeRatioRef,
  overlayImgDrawingRef,
  overlayContainerRef,
  isSpaceRequired,
  isDrawingActive,
}) => {
  const orderState = useStoreState((state) => ({
    gallery: state.order.gallery,
  }))

  const [isCollapsed, setIsCollapsed] = useState(JSON.parse(localStorage.getItem('isCommentBlockCollapsed')) || false)

  useEffect(() => {
    localStorage.setItem('isCommentBlockCollapsed', isCollapsed)
  }, [
    isCollapsed,
  ])

  return (
    <div
      className="collapsible-comment"
      style={{
        right: 0,
        borderTopRightRadius: 0,
      }}
    >
      <div className="collapse-toggle" onClick={() => setIsCollapsed((prev) => !prev)}>
        <ExpandSvg style={{ rotate: isCollapsed ? '180deg' : 'none' }} />
      </div>
      {isCollapsed && (
        <div className="collapsible-comment__content">
          <div className="collapsible-comment__active-comments scrollbar-overflow scrollbar-overflow__small">
            <div className="collapsible-comment__active-comments-title">
              {Translation.active_comments}
            </div>

            {image?.image_comments.map((imageComment) => (
              <CommentOverlay
                key={imageComment.id}
                comment={imageComment}
                overlayImgDrawingRef={overlayImgDrawingRef}
                overlayContainerRef={overlayContainerRef}
              />
            ))}
          </div>

          {orderState.gallery?.is_allowed_to_comment ? (
            <Comment
              refreshGallery={refreshGallery}
              resetInterval={resetInterval}
              resizeRatioRef={resizeRatioRef}
              overlayImgDrawingRef={overlayImgDrawingRef}
              overlayContainerRef={overlayContainerRef}
              isSpaceRequired={isSpaceRequired}
              isDrawingActive={isDrawingActive}
            />
          ) : null}
        </div>
      )}
    </div>
  )
}

CollapsibleCommentOverlay.propTypes = {
  image: PropTypes.instanceOf(Object),
  refreshGallery: PropTypes.func,
  resetInterval: PropTypes.func,
  resizeRatioRef: PropTypes.instanceOf(Object),
  overlayImgDrawingRef: PropTypes.instanceOf(Object),
  overlayContainerRef: PropTypes.instanceOf(Object),
  isSpaceRequired: PropTypes.bool,
  isDrawingActive: PropTypes.bool,
}

CollapsibleCommentOverlay.defaultProps = {
  image: {},
  refreshGallery: () => {
  },
  resetInterval: () => {
  },
  resizeRatioRef: {},
  overlayImgDrawingRef: {},
  overlayContainerRef: {},
  isSpaceRequired: false,
  isDrawingActive: true,
}

export default CollapsibleCommentOverlay
