import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as CopySvg } from '../../svg/copy.svg'
import { ReactComponent as CopiedSvg } from '../../svg/preview_check.svg'

import './index.scss'

export const getPositionClass = (position = '') => {
  if (position === 'top') return 'tooltip-top'
  if (position === 'bottom') return 'tooltip-bottom'
  if (position === 'left') return 'tooltip-left'
  if (position === 'right') return 'tooltip-right'
  return ''
}

const Tooltip = ({
  children,
  dataToCopy,
  text,
  copiedText,
  position,
  offset,
  containsHtml,
}) => {
  const [show, setShow] = useState(false)
  const [isCopied, setIsCopied] = useState(false)

  const onCopy = (e) => {
    e.stopPropagation()
    navigator?.clipboard?.writeText(dataToCopy).then(() => {
    })
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 2000)
  }

  return (
    <div
      className="tooltip"
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      {...(dataToCopy && { onClick: onCopy })}
    >
      {children}
      {show && (
        <div
          onMouseEnter={() => setShow(false)}
          className={`tooltip-tooltip__content ${getPositionClass(position)}`}
          style={{
            ...(offset && position === 'left' && { right: `${offset}rem` }),
            ...(offset && position === 'right' && { left: `${offset}rem` }),
            ...(offset && position === 'bottom' && { top: `${offset}rem` }),
            ...(offset && position === 'top' && { bottom: `${offset}rem` }),
          }}
        >
          {isCopied ? (
            <>
              <CopiedSvg className="copied-svg width-8 height-5" />
              <span className="copied-text">{copiedText}</span>
            </>
          ) : (
            <>
              {dataToCopy && (<CopySvg />)}
              {containsHtml ? (
                <span
                  className="copy-text"
                  /* eslint-disable-next-line react/no-danger */
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              ) : <span>{text}</span>}
            </>
          )}
        </div>
      )}
    </div>
  )
}

Tooltip.propTypes = {
  children: PropTypes.node,
  dataToCopy: PropTypes.string,
  text: PropTypes.string,
  copiedText: PropTypes.string,
  position: PropTypes.string,
  offset: PropTypes.number,
  containsHtml: PropTypes.bool,
}

Tooltip.defaultProps = {
  children: null,
  dataToCopy: '',
  text: '',
  copiedText: '',
  position: 'top',
  offset: null,
  containsHtml: false,
}

export default Tooltip
