import React from 'react'
import PropTypes from 'prop-types'

import { Translation } from '../../../helpers/Translation'
import { PX_TO_REM } from '../../../helpers/Constants'

import Tooltip from '../../../components/Tooltip'

import { ReactComponent as FiltersSaveSvg } from '../../../svg/filters_save.svg'

import './index.scss'

const SaveSettings = ({ isVisible = false }) => (
  <div style={{ display: isVisible ? 'block' : 'none' }}>
    <Tooltip
      text={Translation.save_settings}
      position="bottom"
      offset={PX_TO_REM['51']}
    >
      <div className="header--filters__save">
        <FiltersSaveSvg />
      </div>
    </Tooltip>
  </div>
)

SaveSettings.propTypes = {
  isVisible: PropTypes.bool,
}

SaveSettings.defaultProps = {
  isVisible: false,
}

export default SaveSettings
