import React from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

import Range from '../../../components/Range'

import { ReactComponent as GridSizeBigSvg } from '../../../svg/grid_size_big.svg'
import { ReactComponent as GridSizeSmallSvg } from '../../../svg/grid_size_small.svg'

import './index.scss'

const ImageSizeRange = () => {
  const orderState = useStoreState((state) => ({
    imageSizeSlide: state.order.imageSizeSlide,
  }))

  const orderActions = useStoreActions((actions) => ({
    setImageSizeSlide: actions.order.setImageSizeSlide,
  }))

  return (
    <div className="header--image-size__wrap">
      <Range
        value={orderState.imageSizeSlide}
        min={1}
        max={10}
        leftIcon={<GridSizeSmallSvg />}
        rightIcon={<GridSizeBigSvg />}
        onChange={(e) => {
          e.preventDefault()
          orderActions.setImageSizeSlide(parseInt(e.target.value, 10))
        }}
      />
    </div>
  )
}

export default ImageSizeRange
