import React, { useEffect, useRef, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { MY_DOOPIC_URLS } from '../../helpers/Urls'
import {
  IMAGE_TYPES, IMAGES_PER_PAGE,
  PX_TO_REM,
  SKELETON_CLASS,
} from '../../helpers/Constants'
import { Translation } from '../../helpers/Translation'
import {
  formatFilesize,
  getContrastColor,
  getImageCount,
  useEventListener,
} from '../../helpers/Utils'

import Typography from '../../components/Typography'

import InfoBar from '../Gallery/InfoBar'

import { ReactComponent as ScissorSvg } from '../../svg/scissor.svg'
import { ReactComponent as PlusSvg } from '../../svg/plus.svg'
import { ReactComponent as ArrowUpwardSvg } from '../../svg/arrow_upward.svg'
import { ReactComponent as MagnifierSvg } from '../../svg/magnifier.svg'
import { ReactComponent as LoaderSvg } from '../../svg/loader.svg'

import './index.scss'

const List = () => {
  const userState = useStoreState((state) => ({
    user: state.user.user,
  }))

  const layoutState = useStoreState((state) => ({
    isInfosOpened: state.layout.isInfosOpened,
    isListView: state.layout.isListView,
  }))

  const layoutActions = useStoreActions((actions) => ({
    updateIsListView: actions.layout.updateIsListView,
  }))

  const orderState = useStoreState((state) => ({
    orderId: state.order.orderId,
    imageType: state.order.imageType,
    gallery: state.order.gallery,
    images: state.order.images,
    isImagesLoading: state.order.isImagesLoading,
    previewsBackground: state.order.previewsBackground,
  }))

  const orderActions = useStoreActions((actions) => ({
    getImages: actions.order.getImages,
    getGallery: actions.order.getGallery,
  }))

  const [textColor] = useState('#FFFFFF')
  const [skeletonCount, setSkeletonCount] = useState(0)
  const [imageCount, setImageCount] = useState(0)
  const [showScrollToTop, setShowScrollToTop] = useState(false)
  const [isGeneratingCSV, setIsGeneratingCSV] = useState(false)
  const [showEmptyResult, setShowEmptyResult] = useState(false)

  const galleryContentRef = useRef()
  const emptyResultRef = useRef(null)

  useEffect(() => {
    if (!orderState.images && !orderState.isImagesLoading) {
      const timer = setTimeout(() => {
        setShowEmptyResult(true)
      }, 500)
      return () => clearTimeout(timer)
    }
    return () => {
    }
  }, [
    orderState.images,
    orderState.isImagesLoading,
  ])

  useEffect(() => {
    layoutActions.updateIsListView(true)
  }, [])

  const initGallery = async () => {
    if (Object.keys(userState.user).length === 0
      || !orderState.orderId
      || !orderState.imageType) return

    // Get gallery data
    await orderActions.getGallery({
      is_admin: userState.user.is_admin,
      ...orderState,
    })

    // Optionally add a brief delay before getting images
    if (orderState.isImagesLoading) { // without this if, empty page flash happens
      // eslint-disable-next-line no-promise-executor-return
      // await new Promise((resolve) => setTimeout(resolve, 100))
    }

    await orderActions.getImages({
      is_admin: userState.user.is_admin,
      ...orderState,
    })
  }

  useEffect(() => {
    if (Object.keys(userState.user).length === 0
      || !orderState.orderId
      || !orderState.imageType) return

    if (!orderState.images || orderState.images.length === 0) {
      initGallery().then(() => {
      })
    }

    // Scroll gallery content as desired
    galleryContentRef.current?.scrollTo({ behavior: 'smooth', top: 0 })
  }, [
    userState.user.is_admin,
    orderState.orderId,
    orderState.imageType,
  ])

  useEffect(() => {
    const count = getImageCount(
      orderState.imageType,
      orderState.gallery?.input_count,
      orderState.gallery?.output_count,
      orderState.gallery?.compare_count,
    )

    setImageCount(count)

    if (count) {
      setSkeletonCount(count < IMAGES_PER_PAGE ? count : IMAGES_PER_PAGE)
    }
  }, [
    orderState.gallery?.input_count,
    orderState.gallery?.output_count,
    orderState.gallery?.compare_count,
    orderState.imageType,
  ])

  useEventListener('scroll', (e) => {
    if (e.target === galleryContentRef.current) {
      const { scrollTop } = galleryContentRef.current
      setShowScrollToTop(scrollTop > 800)
    }
  }, galleryContentRef.current)

  const downloadCSV = async () => {
    if (!orderState.images) return
    if (orderState.images.length === 0) return

    setIsGeneratingCSV(true)

    await orderActions.getImages({
      is_admin: userState.user.is_admin,
      ...orderState,
    })

    // Create data array starting with headers
    const data = [
      ['ID', 'Name', 'Width', 'Height', 'DPI', 'Size (KB)', 'Color Space', 'Background Color'],
    ]

    // Using map instead of forEach
    const imageRows = orderState.images.map((image) => [
      image.id,
      image.name,
      image.width,
      image.height,
      image.dpi,
      image.size_kb,
      image.colour_space,
      image.background_color,
    ])

    // Combine the header with the mapped rows
    data.push(...imageRows)

    // Convert array to CSV format with proper newline handling
    const csvContent = data.map((row) => row.join(';')).join('\r\n')

    // Create a Blob object
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

    // Create a download link
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.setAttribute(
      'download',
      // eslint-disable-next-line max-len
      `${orderState.orderId}_${orderState.imageType === IMAGE_TYPES.input ? 'input' : 'output'}_${orderState.images.length}_images.csv`,
    )

    // Append, trigger download, then remove the link
    await document.body.appendChild(link)
    link.click()
    await document.body.removeChild(link)

    setIsGeneratingCSV(false)
  }

  return (
    <>
      <div
        ref={galleryContentRef}
        className="gallery-content gallery-content--list scrollbar-overflow scrollbar-overflow__light"
      >
        {
          // eslint-disable-next-line no-nested-ternary
          (!orderState.images && orderState.isImagesLoading) ? (
            skeletonCount > 0 ? (
              <>
                <div className="gallery-content__header">
                  <div className="gallery-content__header__title">
                    <Typography
                      label={Translation.list_view}
                      fontSize={PX_TO_REM['18']}
                      lineHeight={PX_TO_REM['28']}
                      font="semibold"
                    />
                    <Typography
                      label={Translation.list_view_description}
                      variant="xs"
                    />
                  </div>
                </div>

                <div className="image-row">
                  <div className="col--header col-id">
                    {Translation.image_id}
                  </div>
                  <div className="col--header col-filename">
                    {Translation.filename}
                  </div>
                  <div className="col--header col-width">
                    {Translation.width}
                  </div>
                  <div className="col--header col-height">
                    {Translation.height}
                  </div>
                  <div className="col--header col-resolution">
                    {Translation.resolution}
                  </div>
                  <div className="col--header col-size">
                    {Translation.size}
                  </div>
                  <div className="col--header col-color-space">
                    {Translation.colour_space}
                  </div>
                  <div className="col--header col-background">
                    {Translation.background}
                  </div>
                  <div className="col--header col-comments" style={{ display: 'none' }}>
                    {Translation.comments}
                  </div>
                </div>

                {[...Array(skeletonCount)].map((_, index) => (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={`skeleton-row-${index}`}
                    className={`image-row ${index % 2 === 0 ? 'image-row-light' : ''}`}
                  >
                    <div className="col-row col-id">
                      <div
                        className={SKELETON_CLASS}
                      />
                    </div>
                    <div className="col-row col-filename">
                      <div
                        className={SKELETON_CLASS}
                        style={{ width: `${index % 2 === 0 ? '50%' : '25%'}` }}
                      />
                    </div>
                    <div className="col-row col-width">
                      <div
                        className={SKELETON_CLASS}
                      />
                    </div>
                    <div className="col-row col-height">
                      <div
                        className={SKELETON_CLASS}
                      />
                    </div>
                    <div className="col-row col-resolution">
                      <div
                        className={SKELETON_CLASS}
                      />
                    </div>
                    <div className="col-row col-size">
                      <div
                        className={SKELETON_CLASS}
                      />
                    </div>
                    <div className="col-row col-color-space">
                      <div
                        className={SKELETON_CLASS}
                      />
                    </div>
                    <div className="col-row col-background">
                      <div
                        className={SKELETON_CLASS}
                      />
                    </div>
                    <div className="col-row col-comments" style={{ display: 'none' }}>
                      {' '}
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className="loader--wrap">
                <LoaderSvg color={getContrastColor(orderState.galleryBackground)} />
              </div>
            )
          ) : (
            // eslint-disable-next-line no-nested-ternary
            orderState.gallery ? (
              // eslint-disable-next-line no-nested-ternary
              orderState.images && orderState.images.length > 0 ? (
                <>
                  <div className="gallery-content__header">
                    <div className="gallery-content__header__title">
                      <Typography
                        label={Translation.list_view}
                        fontSize={PX_TO_REM['18']}
                        lineHeight={PX_TO_REM['28']}
                        font="semibold"
                      />
                      <Typography
                        label={Translation.list_view_description}
                        variant="xs"
                      />
                    </div>
                    <div className="gallery-content__header__actions">
                      <button
                        type="button"
                        className="export-csv-btn"
                        onClick={downloadCSV}
                        disabled={isGeneratingCSV}
                      >
                        <PlusSvg />
                        {isGeneratingCSV ? `${Translation.generating}...` : Translation.export_as_csv}
                      </button>

                      <Typography
                        label={`${imageCount} ${Translation.image_will_be_exported}`}
                        fontSize={PX_TO_REM['10']}
                        lineHeight={PX_TO_REM['24']}
                      />
                    </div>
                  </div>

                  <div className="image-row">
                    <div className="col--header col-id">
                      {Translation.image_id}
                    </div>
                    <div className="col--header col-filename">
                      {Translation.filename}
                    </div>
                    <div className="col--header col-width">
                      {Translation.width}
                    </div>
                    <div className="col--header col-height">
                      {Translation.height}
                    </div>
                    <div className="col--header col-resolution">
                      {Translation.resolution}
                    </div>
                    <div className="col--header col-size">
                      {Translation.size}
                    </div>
                    <div className="col--header col-color-space">
                      {Translation.colour_space}
                    </div>
                    <div className="col--header col-background">
                      {Translation.background}
                    </div>
                    <div className="col--header col-comments" style={{ display: 'none' }}>
                      {Translation.comments}
                    </div>
                  </div>

                  {orderState.images.map((image, index) => (
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      data-id={image.id}
                      className={`image-row ${index % 2 === 0 ? 'image-row-light' : ''}`}
                    >
                      <div className="col-row col-id">
                        {image.id}
                      </div>
                      <div className="col-row col-filename">
                        {image.name}
                      </div>
                      <div className="col-row col-width">
                        {`${image.width} ${Translation.px}`}
                      </div>
                      <div className="col-row col-height">
                        {`${image.height} ${Translation.px}`}
                      </div>
                      <div className="col-row col-resolution">
                        {`${image.dpi} ${Translation.dpi}`}
                      </div>
                      <div className="col-row col-size">
                        {formatFilesize(image.size_kb)}
                      </div>
                      <div className="col-row col-color-space">
                        {image.colour_space}
                      </div>
                      <div className="col-row col-background">
                        {image.background_color}
                      </div>
                      <div className="col-row col-comments" style={{ display: 'none' }}>
                        {' '}
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                !imageCount ? (
                  <div className="empty-result">
                    <ScissorSvg
                      style={{ color: textColor }}
                    />
                    {orderState.imageType === IMAGE_TYPES.input ? (
                      <h6
                        className="h6 m-top-5"
                        style={{ color: textColor }}
                      >
                        {Translation.sorry_no_images_found}
                      </h6>
                    ) : (
                      <h6
                        className="h6 m-top-5"
                        style={{ color: textColor }}
                      >
                        {Translation.no_output_images_found_1}
                        <br />
                        {Translation.no_output_images_found_2}
                      </h6>
                    )}
                  </div>
                ) : null
              )
            ) : (
              <div
                ref={emptyResultRef}
                className="empty-result"
                style={{ display: showEmptyResult ? 'block' : 'none' }}
              >
                <MagnifierSvg
                  style={{ color: textColor }}
                />
                <h6
                  className="h6 m-top-5"
                  style={{ color: textColor }}
                >
                  {Translation.we_could_not_find_order}
                  {' '}
                  <a
                    href={MY_DOOPIC_URLS.support}
                    target="_blank"
                    rel="noreferrer"
                    style={{ borderBottomColor: textColor }}
                  >
                    {Translation.support}
                  </a>
                  {' '}
                  {Translation.for_assistance}
                  .
                </h6>
              </div>
            )
          )
        }

        {showScrollToTop && (
          <button
            type="button"
            aria-label="Scroll to top"
            className="scroll-to-top"
            onClick={() => galleryContentRef.current?.scrollTo({ behavior: 'smooth', top: 0 })}
          >
            <ArrowUpwardSvg />
          </button>
        )}
      </div>

      {layoutState.isInfosOpened && (
        <InfoBar />
      )}
    </>
  )
}

export default List
